import axios from 'axios';
import jwt from 'jwt-decode';
import moment from 'moment';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { checkDataAccessFilters, checkRoles } from '../utils/util';

import {
	// AGENT_BREAK_DETAILS_REPORT,
	// AGENT_DETAILS_MOCK,
	// AGENT_EVENT_MOCK,
	// AGENT_LIST_MOCK,
	// AGENT_LOGIN,
	// AGENT_LOGIN_MODAL_TABLE,
	// AGENT_NAMES,
	// AGENT_PERFORMANCE_CALL_MODE_LIVE,
	// AGENT_PERFORMANCE_CALL_TYPE_LIVE,
	// AGENT_PERFORMANCE_MOCK,
	// AGENT_STATE_COUNTER_MOCK,
	// AGENT_STATE_COUNT_MOCK,
	// AGENT_STATE_DETAIL_MOCK,
	// AGENT_STATUS_SKILL_REPORT,
	// CALL_BACKS,
	// CALL_COUNTS_MOCK,
	// CALL_DETAILS_LIVE_REPORT,
	// CALL_DETAILS_MODAL_REPORT,
	// CALL_DETAILS_REPORT,
	// CALL_STATUS_SINGLE_MOCK,
	// COMPARATIVE_CALL_TRENDS_MOCK,
	// CUSTOMER_HOLD_LIVE_REPORT,
	// CUSTOMER_INTERACTION_REPORT,
	// DAILER_COUNT_REPORT,
	// DATA_SUMMARY_MOCK,
	// DAYWISE_NUMBEROF_CALLS,
	// DIGITAL_DETAILS_LIVE_REPORT,
	// DIPOSITION_SUMMARY_LIVE,
	// DIPOSITION_SUMMARY_LIVE_1,
	// DROP_RATIO,
	// FILTERS_MOCK,
	// HOURLY_DIGITAL_SUMMARY,
	// IVR_FEEDBACK_REPORT,
	// IVR_HANGUPS_REPORT,
	// LIVECALLS_MOCK,
	// MISSED_CALL_LIVE_REPORT,
	// OUTBOUND_CAMPAIGN_STATUS_LIVE,
	// OUTBOUND_PACING,
	// PHONE_DASHBOARD,
	// PHONE_PRODUCTIVITY_DETAILED_REPORTS,
	// PHONE_PRODUCTIVITY_REPORTS,
	// PREDICTIVE_PERFORMANCE_LIVE_REPORT,
	// PRI_UTILISATION,
	// QUEUE_COUNTER_MOCK,
	// SCHEDULED_REPORTS_MOCK,
	// SKILL_SUMMARY_MOCK,
	// SKILL_WISE_SKILL_REPORT,
	// SLA_LIVE_REPORT,
	// SLA_MOCK,
	// SMS_LIVE_REPORT,
	// TOP_3_AGENTS,
	// TOP_5_OUTLIERS_MOCK,
	// TOP_5_SKILLS_MOCK,
	// TOP_DISPOSITION_MOCK,
	// VOICE_MAIL_REPORT
} from '../mocks/mock-data';
import { API, secondaryDID } from '../utils/constants';
import { clearSession, getAuthToken, getUserId, getParentUserId } from './auth-service';
// Extend dayjs with the plugins
dayjs.extend(utc);
dayjs.extend(timezone);
type HTTP_METHOD = 'GET' | 'PUT' | 'POST' | 'DELETE';

const getHeaders = () => {
	return { 'Content-Type': 'application/json' };
};

// const getMultipartHeaders = () => {
// 	return { 'Content-Type': 'multipart/form-data' };
// };

// const getAuthHeaders = () => {
// 	// return { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthToken()}` };
// 	return { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthToken()}`, userId: `${getUserId()}`, dAccessType: 'false' };
// };

// const getAuthHeadersLegacy = () => {
// 	// return { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthToken()}` };
// 	return { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthToken()}`, userId: `${getUserId()}`, dAccessType: 'true' };
// };

const getMultipartHeaders = () => {
	try{
		if (isJwtTokenExpired()) {
			clearSession();
			window.location.href = '/login';
			return { 'Content-Type': 'multipart/form-data', Authorization: `Bearer `, userId: ``, dAccessType: 'false' };
		}
		return { 'Content-Type': 'multipart/form-data' };

	}catch(e){
		console.log(e);
		return { 'Content-Type': 'multipart/form-data', Authorization: `Bearer `, userId: ``, dAccessType: 'false' };
	}
};

const getAuthHeaders = () => {
	try{
		if (isJwtTokenExpired()) {
			clearSession();
			window.location.href = '/login';
			return { 'Content-Type': 'application/json', Authorization: `Bearer `, userId: ``, dAccessType: 'false' };
		}
		return { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthToken()}`, userId: `${getUserId()}`, dAccessType: 'false' };
	}catch(e) {
		console.log(e);
		return { 'Content-Type': 'application/json', Authorization: `Bearer `, userId: ``, dAccessType: 'false' };
	}
};

const getAuthHeadersLegacy = () => {
	try{
		if (isJwtTokenExpired()) {
			clearSession();
			window.location.href = '/login';
			return { 'Content-Type': 'application/json', Authorization: `Bearer `, userId: ``, dAccessType: 'true' };
		}
		return { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthToken()}`, userId: `${getUserId()}`, dAccessType: 'true' };

	}catch(e){
		console.log(e);
		return { 'Content-Type': 'application/json', Authorization: `Bearer `, userId: ``, dAccessType: 'true' };
	}
};

const getCurrentDate = () => {
	// let dt = moment(new date(),"yyyy-mm-dd HH:mm:ss");
	let date = moment(new Date()).format("yyyy-MM-DD HH:mm:ss");
	return date;
}

const makeApiCall = async (URL: string, method: HTTP_METHOD, data: any = null, withUserId: boolean = false, mockData: any = null) => {
	try {
		if (isJwtTokenExpired()) {
			clearSession();
			window.location.href = '/login';
			return { status: false, response: null };
		}
		const body = withUserId ? { ...data, UserId: getUserId() } : { ...data };

		const requestParams: any = { method, headers: mockData === "legacy" ? getAuthHeadersLegacy() : getAuthHeaders() };


		if (data) {
			requestParams.body = JSON.stringify(body);
		}
		const response = await fetch(URL, { ...requestParams });

		if (response.status === 401) {
			clearSession();
			window.location.href = '/login';
			return { status: false, response: null };
		}

		return { status: true, response };
	} catch (err) {
		console.log(err);
		return { status: false, response: mockData ? mockData : err };
	}
};


const makeApiCallWithParentUser = async (URL: string, method: HTTP_METHOD, data: any = null, withUserId: boolean = false, mockData: any = null) => {
	try {
		if (isJwtTokenExpired()) {
			clearSession();
			window.location.href = '/login';
			return { status: false, response: null };
		}

		let parentUserJson = getParentUserId();
		let parentUser = parentUserJson === "null" ? getUserId() : JSON.parse(parentUserJson as string).id;


		const body = withUserId ? { ...data, UserId: parentUser } : { ...data };
		const requestParams: any = { method, headers: getAuthHeaders() };
		if (data) {
			requestParams.body = JSON.stringify(body);
		}
		const response = await fetch(URL, { ...requestParams });
		return { status: true, response };
	} catch (err) {
		console.log(err);
		return { status: false, response: mockData ? mockData : err };
	}
};

const makeApiCall1 = async (URL: string, method: HTTP_METHOD, data: any = null, withUserId: boolean = false, mockData: any = null) => {
	try {
		if (isJwtTokenExpired()) {
			clearSession();
			window.location.href = '/login';
			return { status: false, response: null };
		}
		const body = withUserId ? { ...data, UserId: getUserId() } : { ...data };
		const requestParams: any = { method, headers: getAuthHeaders() };
		if (data) {
			requestParams.body = JSON.stringify(body);
		}
		let response = await axios.post(URL, data, { headers: getAuthHeaders() }).then((response) => {
			return response;
		}).catch((err) => {
			return err;
		});

		console.log("A[i response check from makeAPI1---> ", response);
		return { status: true, response };
	} catch (err) {
		console.log(err);
		return { status: false, response: mockData ? mockData : err };
	}
};

export const isJwtTokenExpired = () => {
	let isExpired = false;
	let decodedToken: any;
	const token = getAuthToken();
	try {
		if (token !== null && token !== '') {
			decodedToken = jwt(token);
			const dateNow = new Date();
			if (decodedToken.exp * 1000 < dateNow.getTime()) {
				isExpired = true;
			} else {
				isExpired = false;
			}
			return isExpired;
		} else {
			return false;
		}
	} catch (err) {
		return false;
	}
};

const FILTER_TYPE_API_ID: { [key: string]: number } = {
	Skill: 71,
	Campaign: 72,
	Group: 73,
	Location: 74,
	Detail: 75,
	Position: 76,
	Detail2: 75,
	Detail3: 75,
};

export const getFilters = async (filterType: string, additionalParams = {}) => {
	let tempAdd: any = {}; //this i done for Dynamic Widget and also existing procedures where i am calling the Skill Deatils
	let prevAdditionalParams: any = additionalParams;
	if (filterType === "Detail") {
		tempAdd = {
			SkillDetailNo: "1"
		}
	}
	if (filterType === 'Detail2') {
		tempAdd = {
			SkillDetailNo: "2"
		}
	}
	if (filterType === 'Detail3') {
		tempAdd = {
			SkillDetailNo: "3"
		}
	}
	// additionalParams = tempAdd;
	additionalParams = {
		...tempAdd,
		...prevAdditionalParams,
		AccessType: checkRoles("ROLE_DATA_ACCESS") ? 'Data' : 'Config'
	};
	const data: any = {
		apiId: FILTER_TYPE_API_ID[filterType],
		...additionalParams
	};
	const URL = `${API.BASE_URL}/monitor/api`;
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};


export const getFiltersNew = async (filterType: string) => {
	const data: any = {
		apiId: filterType,
	};
	const URL = `${API.BASE_URL}/monitor/api`;
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getWidgetConfig = async (widgetId: string, allowedFilters: string) => {
    const URL = `${API.BASE_URL}/monitor/api`;
    const data: any = {
        apiId: widgetId,
		allowedFilters: allowedFilters
    };
    const { status, response } = await makeApiCall(URL, 'POST', data, true);
    return status ? response?.json() : response;
};

export const getSLAData = async ({ filters = '0', type = '2', dateType = '0' }) => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 101,
		Type: type,
		FilterId: filters,
		DateTypeId: dateType
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getApiUtilizationData = async ({ fromTime = '', toTime = '', apiName = '', reportType = '', apiTrakingId = '' }) => {
	const URL = `${API.REPORTS_URL}/apiTrackingWidget`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		apiName: apiName,
		apiTrakingId: apiTrakingId,
		reportType: reportType,
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, false);
	return status ? response?.json() : response;
};

export const getPerformanceMetricsData = async ({ callType = 'call', type = 1, filters = '0' }) => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 147,
		Type: type,
		FilterId: filters,
		UserId: getUserId(),
		CallType: callType === 'call' ? 'voice,outbound' : 'digital'
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getCallStatusData = async (params = { filters: '0', type: '1', callType: 'preview', dateTypeId: '0' }) => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 105,
		Type: params.type,
		FilterId: params.filters,
		CallType: params.callType,
		DateTypeId: params.dateTypeId
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getActiveCalls = async (): Promise<any> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 64
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getAgentStateCounter = async (params = { filters: '0', type: '0' }): Promise<any[] | undefined> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 102,
		Type: params.type,
		FilterId: params.filters
		// AccessType: checkRoles("ROLE_DATA_ACCESS") ? 'Data' : 'Config'
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getTopDispositionAndCount = async (params = { filters: '0', type: '1', callType: '0', disposition: '0', limit: '10' }): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 106,
		Type: params.type,
		FilterId: params.filters,
		CallType: params.callType,
		Disposition: params.disposition,
		Limit: params.limit
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getTopDispositionAndCountTable = async (params = { filters: '0', type: '1', callType: '0', disposition: '0', limit: '10' }): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 144,
		Type: params.type,
		FilterId: params.filters,
		CallType: params.callType,
		Disposition: params.disposition,
		Limit: params.limit
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getQueueCounter = async (params = { filters: '0', type: '1', ChannelType: '1' }): Promise<any[] | undefined> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 103,
		Type: params.type,
		FilterId: params.filters,
		ChannelType: params.ChannelType
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getInteractionQueueCounter = async (params = { filters: '0', type: '1', CallQueueType: 'SkillWise', CallType: 'calls' }): Promise<any[] | undefined> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 151,
		Type: params.type,
		FilterId: params.filters,
		CallQueueType: params.CallQueueType,
		CallType: params.CallType === 'calls' ? 'InBound,Progressive,Preview,Predictive,IVR' : 'Chat'
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

// export const getInteractionQueueCounterChats = async (params = { filters: '0' }): Promise<any[] | undefined> => {
// 	const URL = `${API.BASE_URL}/monitor/api`;
// 	const data: any = {
// 		apiId: 154,
// 		campaignId: params.filters === '0' ? '' : params.filters,
// 	};
// 	const { status, response } = await makeApiCall(URL, 'POST', data, true);
// 	return status ? response?.json() : response;
// };

export const getOverallQueueCounter = async (params = { filters: '0', type: '2' }): Promise<any[] | undefined> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 145,
		Type: params?.type,
		// Location: parseInt(params.type) === 4 ? params.filters : "0",
		// Detail: parseInt(params.type) === 5 ? params.filters : "0",
		FilterId: params.filters
		// AccessType: checkRoles("ROLE_DATA_ACCESS") ? 'Data' : 'Config'
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getLocationQueueCounter = async (params = { filters: '0', type: '1' }): Promise<any[] | undefined> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 145,
		Type: params?.type,
		// Location: parseInt(params.type) === 4 ? params.filters : "0",
		// Detail: parseInt(params.type) === 5 ? params.filters : "0",
		FilterId: params.filters
		// AccessType: checkRoles("ROLE_DATA_ACCESS") ? 'Data' : 'Config'
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getHourlyCallSummary = async (params = { filters: '0', type: '1' }): Promise<any[] | undefined> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 108,
		Type: params.type,
		FilterId: params.filters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getOverallInteractionTrendSummary = async (params = { filters: '0', type: '1', bound: '', fromTime: '', toTime: '', }): Promise<any[] | undefined> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 150,
		Type: params.type,
		FilterId: params.filters,
		InteractionType: params.bound,
		FromTime: params.fromTime,
		ToTime: params.toTime
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getSkillSummary = async ({ type = 1, filters = '0' }): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 109,
		Type: type,
		FilterId: filters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getAgentPerformance = async (params = { type: 3, filters: '0' }): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 107,
		Type: params.type,
		FilterId: params.filters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getAgentList = async (params = { type: 0, filters: '0' }): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 104,
		// apiId: checkRoles("ROLE_DATA_ACCESS") ? 603 : 104,
		Type: params.type,
		FilterId: params.filters
		// AccessType: checkRoles("ROLE_DATA_ACCESS") ? 'Data' : 'Config'
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getAgentDetailById = async (agentid: string) => {
    const URL = `${API.BASE_URL}/monitor/api`;
    const data: any = {
        apiId: 67,
		id: agentid
    };
    const { status, response } = await makeApiCall(URL, 'POST', data, true);
    return status ? response?.json() : response;
};

export const getChatStatus = async (params = { type: '1', filters: '0', callType: 'All' }): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 135,
		// Type: 1,
		Type: params.type,
		FilterId: params.filters,
		DigitalType: params.callType
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getHourlyDigitalCallTrends = async (params = { filters: '0', type: '1', callType: 'all' }): Promise<any[] | undefined> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 136,
		Type: params.type,
		FilterId: params.filters,
		DigitalType: params.callType,
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getAgentStateDetail = async (params = { agentId: '' }): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 1041,
		FilterId: params.agentId,
		Type: '1'
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getAgentEvents = async (params = { agentId: '' }): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const userTZ = localStorage.getItem('userTZ') || '';
	const formattedDate = dayjs().tz(userTZ).format('YYYY-MM-DD');
	const data: any = {
		apiId: 1042,
		AgentID: params.agentId,
		loginTime: `${formattedDate} 00:00:00`,
		logoutTime: `${formattedDate} 23:59:59`
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const logoutAgent = async (params = { agentUniqueID: null, agentId: null }): Promise<any> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		action: 'logoffAgentByAdmin',
		apiId: 62,
		agentId: params.agentId,
		agentUniqueId: params.agentUniqueID
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true, 'ERROR');
	return status ? response?.text() : response;
};

export const releaseAgent = async (params = { agentUniqueID: null, agentId: null }): Promise<any> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		action: 'releaseAgentByAdmin',
		apiId: 61,
		agentId: params.agentId,
		agentUniqueId: params.agentUniqueID
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true, 'ERROR');
	return status ? response?.text() : response;
};
export const releaseChat = async (params = { sessionID: null }): Promise<any> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		action: 'releaseChatByAdmin',
		apiId: 66,
		sessionId: params.sessionID
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true, 'ERROR');
	return status ? response?.text() : response;
};

export const login = async (params = { user: '', password: '' }): Promise<any> => {
	try {
		let domain = window.location.href;
		const URL = `${API.LOGIN_URL}`;
		const data: any = { username: params.user, password: params.password };
		const response = await fetch(URL, { method: 'POST', body: JSON.stringify(data), headers: getHeaders() });
		console.log("what is my response code:::::::::::::",response.status);

		if (response.status === 403) {
            throw new Error('FORBIDDEN_ACCESS');
        }

		return response.json();
	} catch (err) {
		console.log('Login Error', err);
		return err;
	}
};

export const validateOAuth2 = async (params = { user: '', ssid: '', code: '', provider: ''}): Promise<any> => {
	try {
		let domain = window.location.href;
		const URL = `${API.OAUTH2_VALIDATE_URL}`;
		const data: any = { username: params.user, ssoToken: params.ssid, code:  params.code, provider: params.provider };
		const response = await fetch(URL, { method: 'POST', body: JSON.stringify(data), headers: getHeaders() });
		return response.json();
	} catch (err) {
		console.log('Login Error', err);
		return err;
	}
};

export const resetPassword = async (params = { user: '', password: '', otp: '' }): Promise<any> => {
	try {
		const URL = `${API.UPDATE_PASSWORD_URL}`;
		const data: any = { username: params.user, password: params.password, otp: params.otp };
		const response = await fetch(URL, { method: 'POST', body: JSON.stringify(data), headers: getHeaders() });
		return response.json();
	} catch (err) {
		console.log('Reset Error', err);
		return err;
	}
};

export const forgotPassword = async (params = { user: '' }): Promise<any> => {
	try {
		const URL = `${API.FORGOT_PASSWORD_URL}/${params.user}`;
		const response = await fetch(URL, { method: 'GET' });
		return response.json();
	} catch (err) {
		console.log('Forgot Password Error', err);
		return err;
	}
};

export const getDynamicWidgetAPIForHeaders = async (params: any): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data = params;
	const { status, response } = await makeApiCall(URL, 'POST', data, false);
	return status ? response?.json() : response;
};

export const getDynamicWidgetAPI = async (params: any): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	// const data: any = {
	//     apiId: params.apiId,
	//     Type: params.type,
	//     FilterId: params.filters
	// };
	const data = params;
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const loginForMfa = async (params = { event: '', user: '', password: '', fullToken: '', fullUserDetails: '',otpMethodTyp: '' }): Promise<any> => {
	try {
		const URL = `${API.LOGIN_URL}`;
		const data: any = { username: params.user, password: params.password, type: "mfaVerify", otp: params.event,otpType: params.otpMethodTyp};
		const response = await fetch(URL, {
			method: 'POST', body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${params.fullToken}`,
				userId: params.fullUserDetails,
				dAccessType: 'false'
			}
		});
		return response?.status === 401 ? "Unauthorized" : response.json();
		// return response?.status !== 200 ? "Unauthorized" : response.json();
	} catch (err) {
		console.log('Login MFA Error', err);
		return err;
	}
};

export const loginForMfaResend = async (params = { user: '', password: '', fullToken: '', fullUserDetails: '' }): Promise<any> => {
	try {
		const URL = `${API.LOGIN_URL}`;
		const data: any = { username: params.user, password: params.password, type: "mfaResend" };
		const response = await fetch(URL, {
			method: 'POST', body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${params.fullToken}`,
				userId: params.fullUserDetails,
				dAccessType: 'false'
			}
		});
		return response?.status === 401 ? "Unauthorized" : response.json();
		// return response?.status !== 202  ? "Unauthorized" : response.json();
	} catch (err) {
		console.log('Login MFA Resend Error', err);
		return err;
	}
};

export const reportlogin = async (params = { user: '', password: '' }): Promise<any> => {
	try {
		const URL = `${API.REPORT_BASE_URL}/login`;
		const data: any = {
			username: params.user,
			password: params.password
		};
		const response = await fetch(URL, { method: 'POST', body: JSON.stringify(data), headers: getHeaders() });
		return response.json();
	} catch (err) {
		console.log('Login Error', err);
		return err;
	}
};

export const dashboard = async (action: 'list' | 'save' | 'update' | 'delete' = 'list', params: { id?: number | undefined; metadata?: any; SubUsers?: any }): Promise<any> => {
	const URL = `${API.BASE_URL}/monitor/api/`;
	const data: any = { action };
	data.apiId = 137;
	data.SubUsers = "";
	if (params.id) {
		data.id = params.id;
	}
	if (params.metadata) {
		data.Metadata = params.metadata;
	}
	if (params.SubUsers) {
		data.SubUsers = params.SubUsers;
	}
	const { response } = await makeApiCall(URL, 'POST', data, true);
	return response;
};

export const slaThresholdApi = async (action: 'list' | 'save' | 'update' | 'delete' = 'list', AppThreshold?: any): Promise<any> => {
	const URL = `${API.BASE_URL}/cloudagent/api/appThreshold`;
	const data: any = { action, AppThreshold: AppThreshold };
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const bargeIn = async (params = { UCID: '', isSip: false, phoneNo: '', agentNumber: '' }): Promise<any> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	console.log("making apikall");
	const data: any = {
		apiId: 63,
		ucid: params.UCID,
		action: 'CALL_BARGEIN',
		isSip: params.isSip,
		phoneno: params.phoneNo,
		agentNumber: params.agentNumber,
		cbURL: window.location.hostname
	};
	console.log("making apikall urla and dat is", URL, data);
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};


// export const getAgentList = async (params = { type: 0, filters: '0' }): Promise<any[]> => {
// 	const URL = `${API.BASE_URL}/monitor/api`;
// 	const data: any = {
// 		apiId: 104,
// 		Type: params.type,
// 		FilterId: params.filters
// 	};
// 	const { status, response } = await makeApiCall(URL, 'POST', data, true);
// 	return status ? response?.json() : response;
// };

// export const bargeInPhoneNumber = async (params: { sipurl?: string; }): Promise<any> => {
// 	const URL = `${API.BASE_URL}/monitor/api`;
// 	const data: any = {
// 		apiId: 139,
// 		sipURL: params.sipurl,
// 	};
// 	const { response } = await makeApiCall(URL, 'POST', data, true);
// 	return response;
// };

export const bargeInPhoneNumber = async (params = { sipurl: '' }): Promise<any[]> => {
	console.log("Received url bargeInPhoneNumber ===>", params);
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 139,
		sipURL: params.sipurl
		// sipURL: "google"
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const agentStateCounts = async (params = { filters: '0', type: '1' }) => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 110,
		Type: params.type,
		FilterId: params.filters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const runningStatusCalls = async (params = { filters: '0', type: '1' }) => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 2502,
		Type: params.type,
		FilterId: params.filters,
		Key: "",
		ChannelType: '0'
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

// export const runningStatusChats = async (params = { filters: '0'}) => {
// 	const URL = `${API.BASE_URL}/monitor/api`;
// 	const data: any = {
// 		apiId: 153,
// 		campaignId: params.filters === '0' ? '' : params.filters
// 	};
// 	const { status, response } = await makeApiCall(URL, 'POST', data, true);
// 	return status ? response?.json() : response;
// };

export const runningStatusChats = async (params = { filters: '0' }) => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 153,
		campaignId: params.filters === '0' ? '' : params.filters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getTickerData = async ({ filters = '0', dateType = 0, type = 1 } = {}) => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 130,
		Type: type,
		FilterId: filters,
		DateTypeId: dateType
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const callCounts = async (params = { filters: '0', type: 1 }) => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 111,
		Type: params.type,
		FilterId: params.filters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const top5Skills = async (params = { filters: '0', type: 1 }) => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 112,
		Type: params.type,
		FilterId: params.filters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const top3Agents = async (params = { filters: '0', type: 1 }) => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 113,
		Type: params.type,
		FilterId: params.filters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getQueueCounterForTvMonitor = async (params = { filters: '0', type: '1', ChannelType: '0' }): Promise<any[] | undefined> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 103,
		Type: params.type,
		FilterId: params.filters,
		ChannelType: params.ChannelType
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const comparativeCallTrends = async ({ filters = '0', type = '1', callType = 'All', dateTypeId = 0 }) => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 114,
		Type: type,
		FilterId: filters,
		CallType: callType,
		DateTypeId: dateTypeId,
		Flag:1
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const agentDetails = async () => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 116,
		Type: 1,
		FilterId: '0'
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const top5Outliers = async ({ filters = '0', type = '1', callType = 'all', dateTypeId = 1, hour = '11' }) => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 115,
		Type: type,
		FilterId: filters,
		CallType: callType,
		DateTypeId: dateTypeId,
		Hour: hour,
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getLiveCalls = async (params = { campaignId: '0' }): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 119,
		campaignId: params.campaignId,
		campaignType: 'Predictive'
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getDaywiseNumberofcalls = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	page = '',
	rowsPerPage = '',
	reportType = 'All',
	sortOrderColumn = '',
	sortOrderType = 0,
	disabledFilters='1'
}): Promise<any[]> => {
	const URL = `${API.REPORTS_URL}/dayWiseCalls`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, false);
	return status ? response?.json() : response;
};

export const getPortUtilizationDetailsLive = async ({
	duration = '',
	DID = 'All',
}): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1052,
		UserId: getUserId(),
		date: duration,
		DID: DID
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, false);
	return status ? (response.status === 200 ? response?.json() : []) : [];
};

export const getPortUtilizationDetails = async ({
	fromTime = '',
	toTime = '',
	DID = 'All',
	download = false,
}): Promise<any[]> => {
	const URL = `${API.REPORTS_URL}/portUtilizationReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		did: DID,
		download: download
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, false);
	return status ? (response.status === 200 ? response?.json() : []) : [];
};

export const getWfmDashboard = async ({ type = 1, filters = '0' }): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		// apiId: 2501,
		apiId: checkRoles("ROLE_DATA_ACCESS") ? 2503 : 2501,
		Type: type,
		FilterId: filters,
		CallType: "voice",
		Key: "SkillId",
		UserId: getUserId(),
		// AccessType: checkRoles("ROLE_DATA_ACCESS") ? 'Data' : 'Config'
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, false);
	return status ? (response.status === 200 ? response?.json() : []) : [];
};

export const getCPaaSCallStatus = async () => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 1555,
		UserId: getUserId(),
		IntervalMinute: 120
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getPerformanceDashboard = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	agent = 'All',
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/agentProductivityReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		campaignName: campaignName,
		callType: callType,
		agent: agent,
		reportType: reportType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, false);
	return status ? response?.json() : response;
};

export const uploadImage = async (formData: FormData) => {
	try {
		//
		const URL = `${API.IMAGE_UPLOAD_URL}/ImageUpload/upload.php`;
		const response = await fetch(URL, {
			method: 'POST',
			body: formData
		});
		return response.json();
	} catch (err) {
		console.log('Image Upload API error', err);
		return {};
	}
};

export const getHourlyCallSummaryReports = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/hourlyCallSummaryReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		callType: callType,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, false);
	return status ? response?.json() : response;
};

export const getHourlyCallSummaryDetailedReports = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/hourlyCallSummaryDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		callType: callType,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getUserMapper = async (): Promise<any[] | any> => {
	const URL = `${API.REPORTS_URL}/getUserMapper`;
	const { status, response } = await makeApiCall(URL, 'GET');
	console.log("status", status)
	console.log("response", response)

	return response?.status || null;
};

export const getCallDetailsReport = async ({
	fromTime = '',
	UCID = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	phoneName = 'All',
	location = 'All',
	reportType = 'All',
	callStatus = 'All',
	transfered = 'All',
	agent = 'All',
	callEvent = 'All',
	disposition = 'All',
	durationinsecs = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	callID = '',
	callerNo = '',
	durationInput = '',
	uui = '',
	disabledFilters = '1',
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/callDetailsV2Report`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		phoneName: phoneName,
		skill: skill,
		agent: agent,
		location: location,
		callID: callID,
		UCID: UCID,
		fallbackRule: '',
		type: '',
		callStatus: callStatus,
		transfered: transfered,
		callType: callType,
		callEvent: callEvent,
		disposition: disposition,
		reportType: reportType,
		durationinsecs: durationinsecs,
		callerNo: callerNo,
		durationInput: durationInput,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		uui: uui,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, false);
	return status ? response?.json() : response;
};

export const getCallDetailsLegacyReport = async ({
	fromTime = '',
	UCID = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	phoneName = 'All',
	location = 'All',
	reportType = 'All',
	callStatus = 'All',
	transfered = 'All',
	agent = 'All',
	callEvent = 'All',
	disposition = 'All',
	durationinsecs = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	callID = '',
	callerNo = '',
	durationInput = '',
	uui = '',
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/migration/callDetailsV2Report`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		phoneName: phoneName,
		skill: skill,
		agent: agent,
		location: location,
		callID: callID,
		UCID: UCID,
		fallbackRule: '',
		type: '',
		callStatus: callStatus,
		transfered: transfered,
		callType: callType,
		callEvent: callEvent,
		disposition: disposition,
		reportType: reportType,
		durationinsecs: durationinsecs,
		callerNo: callerNo,
		durationInput: durationInput,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		uui: uui,
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, false, "legacy");
	return status ? response?.json() : response;
};

export const getCallDetailsDetailedReport = async ({
	fromTime = '',
	toTime = '',
	campaignName = 'All',
	phoneName = 'All',
	skill = 'All',
	agent = 'All',
	location = 'All',
	callID = '',
	UCID = '',
	fallbackRule = '',
	type = '',
	callStatus = 'All',
	transfered = 'All',
	callType = 'All',
	callEvent = 'All',
	disposition = 'All',
	reportType = 'All',
	durationinsecs = 'All',
	callerNo = '',
	durationInput = '',
	page = 1,
	rowsPerPage = 10,
	sortOrderColumn = '',
	sortOrderType = 0,
	requestExcecutionId = null,
	totalNoOfRows = 0,
	uui = '',
	disabledFilters = '1',
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/callDetailsV2Dump`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		phoneName: phoneName,
		skill: skill,
		agent: agent,
		location: location,
		callID: callID,
		ucid: UCID,
		fallbackRule: fallbackRule,
		type: type,
		callStatus: callStatus,
		transfered: transfered,
		callType: callType,
		callEvent: callEvent,
		disposition: disposition,
		reportType: reportType,
		durationinsecs: durationinsecs,
		callerNo: callerNo,
		durationInput: durationInput,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: requestExcecutionId,
		totalNoOfRows: totalNoOfRows,
		uui: uui,
		filterValue: disabledFilters,
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getCallDetailsDetailedLegacyReport = async ({
	fromTime = '',
	toTime = '',
	campaignName = 'All',
	phoneName = 'All',
	skill = 'All',
	agent = 'All',
	location = 'All',
	callID = '',
	UCID = '',
	fallbackRule = '',
	type = '',
	callStatus = 'All',
	transfered = 'All',
	callType = 'All',
	callEvent = 'All',
	disposition = 'All',
	reportType = 'All',
	durationinsecs = 'All',
	callerNo = '',
	durationInput = '',
	page = 1,
	rowsPerPage = 10,
	sortOrderColumn = '',
	sortOrderType = 0,
	requestExcecutionId = null,
	totalNoOfRows = 0,
	uui = '',
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/migration/callDetailsV2Dump
`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		phoneName: phoneName,
		skill: skill,
		agent: agent,
		location: location,
		callID: callID,
		ucid: UCID,
		fallbackRule: fallbackRule,
		type: type,
		callStatus: callStatus,
		transfered: transfered,
		callType: callType,
		callEvent: callEvent,
		disposition: disposition,
		reportType: reportType,
		durationinsecs: durationinsecs,
		callerNo: callerNo,
		durationInput: durationInput,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: requestExcecutionId,
		totalNoOfRows: totalNoOfRows,
		uui: uui,
	};
	// const { status, response } = await makeApiCall(URL, 'POST', data);
	const { status, response } = await makeApiCall(URL, 'POST', data, false, "legacy");
	return status ? response?.json() : response;
};

export const getSkilsProductivityReports = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/skillProductivityReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		skill: skill,
		reportType: reportType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: 2
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getIVRFeedbackReport = async ({
	fromTime = '',
	toTime = '',
	campaignName = '',
	page = 1,
	rowsPerPage = 10,
	sortOrderColumn = '',
	sortOrderType = 0,
	requestExcecutionId = null,
	totalNoOfRows = 0
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/ivrFeedbackReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: requestExcecutionId,
		totalNoOfRows: totalNoOfRows,
		filterValue: 1
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, false);
	return status ? response?.json() : response;
};

export const getPredictivePerformanceReport = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/predictivePerformanceReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: 1
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getSkilsProductivityDetailedReports = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/skillProductivityDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		skill: skill,
		reportType: reportType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: 2
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getMissedCallReport = async ({
	fromTime = '',
	toTime = '',
	// skill = 'All',
	page = 1,
	rowsPerPage = 10,
	sortOrderColumn = '',
	sortOrderType = 0,
	requestExcecutionId = null,
	totalNoOfRows = 0
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/missedCallReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		// Skill: skill,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: requestExcecutionId,
		totalNoOfRows: totalNoOfRows,
		filterValue: 2
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getConferenceDetails = async ({ fromTime = '', toTime = '', agent = 'All', page = '', rowsPerPage = '', sortOrderColumn = '', sortOrderType = 0 }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/conferenceDetailsReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		agent: agent,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: 8
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getTransferredCallsReport = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	agent = 'All',
	reportType = 'All',
	transfered = 'All',
	phoneName = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/transferredCallsReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		reportType: reportType,
		skill: skill,
		agent: agent,
		transfered: transfered,
		phoneName: phoneName,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getCampaignProductivityReports = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/campaignProductivityReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: 1
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getCampaignProductivityDetailedReports = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/campaignProductivityDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: 1
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getHourlyPhoneCallReports = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	phone = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/hourlyPhoneCallSummaryReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		phone: phone,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getCustomerHoldReport = async ({ fromTime = '', toTime = '', agent = 'All', callerNo = 'All', page = '1', rowsPerPage = '', sortOrderColumn = '', sortOrderType = 0 }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/customerHoldReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		agent: agent,
		callerNo: callerNo,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: 8
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getHourlyPhoneCallDetailedReports = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	phone = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/hourlyPhoneCallSummaryDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		phone: phone,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getUserHangupInIvr = async ({ fromTime = '', toTime = '', campaignName = 'All', page = '', rowsPerPage = '', sortOrderColumn = '', sortOrderType = 0 }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/userHangUpIVRReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: 1
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getPhoneProductivityReports = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	reportType = 'All',
	callType = 'All',
	phoneNumber = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/phoneProductivityReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		skill: skill,
		reportType: reportType,
		phoneNumber: phoneNumber,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, false);
	return status ? response?.json() : response;
};

export const getAuditLogReport = async ({ fromTime = '', toTime = '', page = '', rowsPerPage = '', sortOrderColumn = '', sortOrderType = 0 }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/auditLogReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getCurrentDayAuditLog = async ({
	Type = "1",
	// userID = getUserId(),
	fromLimit = '',
	toLimit = '',
}): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1050,
		Type: Type,
		// UserId: userID,
		FromLimit: fromLimit,
		ToLimit: toLimit,
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getAuditLogCount = async ({
	fromTime = '',
	toTime = '',
}): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 1562,
		ToDate: toTime,
		FromDate: fromTime,
		UserId: getUserId(),
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getAuditLogData = async ({
	fromTime = '',
	toTime = '',
	fromLimit = '',
	toLimit = '',
}): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 1561,
		ToDate: toTime,
		FromDate: fromTime,
		FromLimit: fromLimit,
		ToLimit: toLimit,
		UserId: getUserId(),
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getPhoneProductivityDetailedReports = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	reportType = 'All',
	callType = 'All',
	phoneNumber = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/phoneProductivityDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		skill: skill,
		reportType: reportType,
		phoneNumber: phoneNumber,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, false);
	return status ? response?.json() : response;
};

export const getCallSummaryOutbound = async ({
	fromTime = '',
	toTime = '',
	callType = 'All',
	campaignName = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/callSummaryOutboundReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: 1
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getPhoneDashboardReports = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '1',
	rowsPerPage = '10',
	sortOrderColumn = '',
	sortOrderType = 0,
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/offlinePhoneDashBoard`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, false);
	return status ? response?.json() : response;
};
export const getCallSummaryOutboundDetailedReports = async ({
	fromTime = '',
	toTime = '',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/callSummaryOutboundDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: 1
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getAgentLoginReports = async ({ fromTime = '', toTime = '', agent = 'All', event = 'All', page = '', rowsPerPage = '', sortOrderColumn = '', sortOrderType = 0 }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/agentLoginReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		agent: agent,
		event: event,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: 8
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getAgentProdcutivity = async ({
	fromTime = '',
	toTime = '',
	agent = 'All',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/agentProductivityReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		campaignName: campaignName,
		callType: callType,
		agent: agent,
		reportType: reportType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getSystemSummaryReport = async ({ fromTime = '', toTime = '', sortOrderColumn = '', sortOrderType = 0 }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/systemSummaryReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		pageNo: 1,
		rowsPerPage: 10,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: 1
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getAgentProductivityDetailedReports = async ({
	fromTime = '',
	toTime = '',
	agent = 'All',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/agentProductivityDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		campaignName: campaignName,
		callType: callType,
		agent: agent,
		reportType: reportType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getAgentDispositionReport = async ({
	fromTime = '',
	toTime = '',
	agent = 'All',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	disabledFilters = '8'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/agentDispositionReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		agent: agent,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getAgentDispositionDetailedReport = async ({
	fromTime = '',
	toTime = '',
	agent = 'All',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	disabledFilters = '8'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/agentDispositionDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		agent: agent,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getAgentStateSummaryReport = async ({ fromTime = '', toTime = '', agent = 'All', mode = 'All', page = '', rowsPerPage = '', sortOrderColumn = '', sortOrderType = 0 }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/agentStateSummaryReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		agent: agent,
		mode: mode,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: 8
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getAgentEfficiencyReport = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	agent = 'All',
	sortOrderColumn = '',
	sortOrderType = 0,
	page = 1,
	rowsPerPage = 10,
	totalNoOfRows = 0,
	requestExcecutionId = null
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/agentEfficiencyReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		campaignName: campaignName,
		agent: agent,
		reportType: reportType,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: requestExcecutionId,
		totalNoOfRows: totalNoOfRows
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getAgentStateSummaryDetailedReport = async ({
	fromTime = '',
	toTime = '',
	agent = 'All',
	mode = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/agentStateSummaryDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		agent: agent,
		mode: mode,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: 8
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getHourlyAgentCallReport = async ({
	fromTime = '',
	toTime = '',
	agent = 'All',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	disabledFilters = '8'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/agentHourlyCallInBoundReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		agent: agent,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};
export const getAgentCallSummary = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	agent = 'All',
	callType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	disabledFilters = '8'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/agentCallSummary`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		agent: agent,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getAgentCallDetailedSummary = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	agent = 'All',
	callType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/agentCallSummaryDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		agent: agent,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: 8
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getHourlyAgentCallDetailedReport = async ({
	fromTime = '',
	toTime = '',
	agent = 'All',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	disabledFilters = '8'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/agentHourlyCallInBoundDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		agent: agent,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getHourlyAgentsSeriesData = async ({ fromTime = '', toTime = '', skill = 'All', campaignName = 'All', sortOrderColumn = '', sortOrderType = 0, totalRows = 0, rowsPerPage = '', requestExcecutionId = null, disabledFilters = '1' }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/hourlyAgentSummaryReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		campaignName: campaignName,
		pageNo: 1,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: requestExcecutionId,
		totalNoOfRows: totalRows,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};
export const getFullHourlyAgentsDetailedSeriesData = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	sortOrderColumn = '',
	sortOrderType = 0,
	page = '',
	rowsPerPage = '',
	totalRows = 0,
	requestExcecutionId = null,
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullhourlyAgentSummaryDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		campaignName: campaignName,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: requestExcecutionId,
		totalNoOfRows: totalRows,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getHourlyAgentsDetailedSeriesData = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	sortOrderColumn = '',
	sortOrderType = 0,
	page = '',
	rowsPerPage = '',
	totalRows = 0,
	requestExcecutionId = null,
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/hourlyAgentSummaryDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		campaignName: campaignName,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: requestExcecutionId,
		totalNoOfRows: totalRows,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getChatDispositionSummary = async ({ fromTime = '', page = 1, rowsPerPage = 10, toTime = '', sortOrderColumn = '', sortOrderType = 0 }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/chatDispositionReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: 1
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getAgentBreakDetailsReport = async ({ fromTime = '', toTime = '', agent = 'All', page = '', rowsPerPage = '', sortOrderColumn = '', sortOrderType = 0 }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/agentBreakReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		agent: agent,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		filterValue: 8
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getAgentBreakDetailsDetailedReport = async ({ fromTime = '', toTime = '', agent = 'All', page = '', rowsPerPage = '', sortOrderColumn = '', sortOrderType = 0 }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/agentBreakDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		agent: agent,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		filterValue: 8
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getDigitalDetailReport = async ({ fromTime = '', toTime = '', campaignName = 'All', channelType = 'All', page = '', rowsPerPage = '', sortOrderColumn = '', phone = '', sortOrderType = 0, uui = '' }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/chatDetailReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		phNumber: phone,
		channelType: channelType,
		uui: uui,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: 1
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getDayCallSummaryReports = async ({
	fromTime = '',
	toTime = '',
	callMetrics = 'All',
	callType = 'All',
	dateFormat = '1',
	campaignName = 'All',
	skill = 'All',
	agent = 'All',
	phoneName = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	isCheckedAgent = false,
	isCheckedCalltype = false,
	isCheckedCampaigname = false,
	isCheckedPhonename = false,
	isCheckedSkill = false,
	disabledFilters = '1'

}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/daySummaryDyna`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		callMetrics: callMetrics,
		callType: callType,
		dateFormat: dateFormat,
		campaignName: campaignName,
		skill: skill,
		agent: agent,
		phoneName: phoneName,
		isCheckedCampaignDay: isCheckedCampaigname,
		isCheckedSkillDay: isCheckedSkill,
		isCheckedCallTypeDay: isCheckedCalltype,
		isCheckedPhnoDay: isCheckedPhonename,
		isCheckedAgentDay: isCheckedAgent,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getDigitalDetailsLiveReport = async ({
	fromTime = '2022-11-28 00:00:00',
	toTime = '2022-11-28 23:59:59',
	campaignName = '0',
	UUI = '',
	channelType = '',
	CustomerId = '',
	AgentName = ''
}): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1015,
		FromDate: fromTime,
		ToDate: toTime,
		CampaignId: campaignName,
		ChannelType: channelType == "All" ? "" : channelType,
		CustomerId: CustomerId,
		UUI: UUI,
		agent: AgentName
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getReports = async (custParams: any): Promise<any> => {
	console.log("custparams", custParams);
	const URL = `${API.REPORTS_URL}/customReport`;
	const { status, response } = await makeApiCall(URL, 'POST', custParams);
	return status ? response?.json() : response;
};

export const getCallDetailsLiveReport = async ({
	fromTime = '',
	toTime = '',
	UCID = '0',
	campaignName = '0',
	transfered = -1,
	callEvent = '',
	CallerId = '',
	callType = '',
	callStatus = '',
	day = '1',
	skill = '0',
	agent = '0',
	phoneName = '0',
	location = '0',
	userID = '7076',
	fromLimit = '',
	toLimit = '',
	Type = "1",
	uui = '',
	disposition = '',
	disabledFilters = '1'
}): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1045,
		FromDate: fromTime,
		ToDate: toTime,
		UCID: UCID,
		TransferType: transfered,
		CampaignId: campaignName || 0,
		Rule: callEvent,
		CallerId: CallerId,
		CallType: callType,
		Status: callStatus,
		day: day,
		SkillId: skill,
		AgentId: agent,
		UserId: userID,
		PhoneId: phoneName,
		LocationId: location,
		FromLimit: fromLimit,
		ToLimit: toLimit,
		Type: Type,
		uui: uui,
		disposition: disposition == "0" ? "" : disposition,
		
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getCallDetailsLiveReportData = async ({
	fromTime = '',
	toTime = '',
	UCID = '0',
	campaignName = '0',
	transfered = -1,
	callEvent = '',
	CallerId = '',
	callType = '',
	callStatus = '',
	day = '1',
	skill = '0',
	agent = '0',
	phoneName = '0',
	location = '0',
	userID = '7076',
	fromLimit = '',
	toLimit = '',
	Type = "1",
	uui = '',
	disposition = '',
	disabledFilters = '1'
}): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 5045,
		FromDate: fromTime,
		ToDate: toTime,
		UCID: UCID,
		TransferType: transfered,
		CampaignId: campaignName || 0,
		Rule: callEvent,
		CallerId: CallerId,
		CallType: callType,
		Status: callStatus,
		day: day,
		SkillId: skill,
		AgentId: agent,
		UserId: userID,
		PhoneId: phoneName,
		LocationId: location,
		FromLimit: fromLimit,
		ToLimit: toLimit,
		ReturnCount: Type,
		uui: uui,
		Disposition: disposition == "0" ? "" : disposition,
		Type: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getCallDetailsDumpLiveReportData = async ({
	fromTime = '',
	toTime = '',
	UCID = '0',
	monitorUCID = '0',
	campaignName = '0',
	transfered = -1,
	Rule = '',
	CallerId = '',
	callType = '',
	callStatus = '',
	day = '1',
	skill = '0',
	agent = '0',
	phoneName = '0',
	location = '0',
	Type = "1",
	userID = '7076',
	fromLimit = '',
	toLimit = '',
	uui = '',
	disposition = '',
	disabledFilters = '1'
}): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;

	const data: any = {
		apiId: 5046,
		FromDate: fromTime,
		ToDate: toTime,
		UCID: UCID,
		MonitorUCID: monitorUCID,
		TransferType: transfered,
		CampaignId: campaignName || 0,
		Rule: Rule,
		CallerId: CallerId,
		CallType: callType,
		Status: callStatus,
		day: day,
		SkillId: skill,
		AgentId: agent,
		PhoneId: phoneName,
		LocationId: location,
		ReturnCount: Type,
		UserId: userID,
		FromLimit: fromLimit,
		ToLimit: toLimit,
		uui: uui,
		Disposition: disposition == "0" ? "" : disposition,
		Type: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getScheduledReports = async (): Promise<any[]> => {
	const URL = `${API.REPORTS_URL}/monitor/api`;
	const data: any = {
		apiId: 109
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getAgentPauseBreakReport = async ({ fromTime = '', toTime = '', agent = '0', fromLimit = '', toLimit = '', Type = "1" }): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1048,
		AgentId: agent,
		FromDate: fromTime,
		ToDate: toTime,
		FromLimit: fromLimit,
		ToLimit: toLimit,
		Type: Type,
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

// export const getAgentPauseBreakReport = async ({ fromTime = '', toTime = '', agent = '0' }): Promise<any[]> => {
// 	const URL = `${API.LIVE_API_URL}/monitor/api`;
// 	const data: any = {
// 		apiId: 1006,
// 		AgentId: agent,
// 		FromDate: fromTime,
// 		ToDate: toTime
// 	};
// 	const { status, response } = await makeApiCall(URL, 'POST', data, true, AGENT_BREAK_DETAILS_REPORT);
// 	return status ? response?.json() : response;
// };
export const getIVRFeedbackLiveReport = async ({ fromTime = '', toTime = '', campaignName = '0' }): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1034,
		fromDate: fromTime,
		toDate: toTime,
		campaignName: campaignName
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};
// 7464, 0, '2023-06-28 00:10:57', '2023-07-28 12:10:57', '0', '0', '0', 0, 10

// export const getWhatsappOutboundLiveReport = async ({ Userid = '1', Campaignid = '0', fromTime = '', toTime = '', Templateid = '0', Status = '0', DID = '0', FromLimit = 0, ToLimit = 10 }): Promise<any[]> => {
// 	const URL = `${API.LIVE_API_URL}/monitor/api`;
// 	const data: any = {
// 		apiId: 1051,
// 		UserId: Number(Userid),
// 		CampaignId: Campaignid,
// 		StartTime: fromTime,
// 		EndTime: toTime,
// 		TemplateId: Templateid,
// 		Status: Status,
// 		DID: DID,
// 		FromLimit: FromLimit,
// 		ToLimit: ToLimit,

// 	};
// 	const { status, response } = await makeApiCall(URL, 'POST', data, true);
// 	return status ? response?.json() : response;
// };

export const getWhatsappOutboundLiveReport = async ({ SenderId = 'All', agentName = 'All' }): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 1559,
		UserId: getUserId,
		SenderId: SenderId,
		AgentId: agentName,
	};
	console.log("agentName", agentName)
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getWhatsappOutboundHistoricReport = async ({ SenderId = 'All', agentName = 'All', fromTime = '2024-05-30 00:00:00', toTime = '2024-06-05 23:59:59' }): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;

	const data: any = {
		apiId: 1560,
		UserId: getUserId,
		SenderId: SenderId,
		AgentId: agentName,
		FromDate: fromTime,
		ToDate: toTime
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getCallBacksReport = async ({ fromTime = '', toTime = '', sortOrderColumn = '', sortOrderType = 0 }): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1029,
		FromDate: fromTime,
		ToDate: toTime,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getRatingCommentAPIUpdate = async ({ callId = '', comments = '' }): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 138,
		callId: callId,
		comments: comments
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getRatingsAPIUpdate = async ({ callId = '', rating = '' }): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 138,
		callId: callId,
		rating: rating,
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getMissedCallLiveReport = async ({ fromTime = '', toTime = '', skill = '0' }): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1005,
		FromDate: fromTime,
		ToDate: toTime,
		SkillId: skill
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getReportsHomeData = async (params = { fromTime: '', toTime: '', skill: '', campaignName: '', callType: '', disabledFilters: '1' }): Promise<any[]> => {
	const URL = `${API.REPORTS_URL}/homeDashboard`;
	const data: any = {
		fromTime: params.fromTime,
		toTime: params.toTime,
		skill: params.skill || "All",
		campaignName: params.campaignName || "All",
		callType: params.callType,
		pageNo: 1,
		rowsPerPage: 25,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: params.disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};
export const getAgentStatusSkillReport = async ({ skill = '0' }): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1022,
		SkillId: skill
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getReportsHomeFilters = async (): Promise<any[] | any> => {
	const URL = `${API.FILTERS_URL}/homeDashBoardFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getSystemAnalyticsReport = async (params = { fromTime: '', toTime: '', skill: '', campaignName: '', callType: '', reportType: '', disabledFilters: '1' }): Promise<any[] | any> => {
	console.log("Inside getSystemAnalyticsReport ->",params);
	const URL = `${API.REPORTS_URL}/systemAnalytics`;
	const data: any = {
		fromTime: params.fromTime,
		toTime: params.toTime,
		skill: params.skill || "All",
        campaignName: params.campaignName || "All",
		reportType: params.reportType,
		callType: params.callType,
		pageNo: 1,
		rowsPerPage: 10,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: params.disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getSkillWiseSkillReport = async ({ skill = '0' }): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1021,
		SkillId: skill
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getSLAReportData = async ({ fromTime = '', toTime = '', skill = 'All', campaignName = 'All', reportType = 'All', page = '1', rowsPerPage = '25' }): Promise<any[] | any> => {
	const URL = `${API.REPORTS_URL}/serviceLevelAgreement`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: '',
		sortOrderType: 0,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: 2
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getSMSLiveReport = async ({ fromTime = '', toTime = '' }): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1007,
		FromDate: fromTime,
		ToDate: toTime
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

// export const getCustomerInteractionReport = async ({ fromTime = '', toTime = '', searchInputKey = '0', campaignName = '0', Group = '1' }): Promise<any[]> => {
// 	const URL = `${API.LIVE_API_URL}/monitor/api`;
// 	const data: any = {
// 		apiId: 1011,
// 		FromDate: fromTime,
// 		ToDate: toTime,
// 		CampaignId: campaignName,
// 		CallId: searchInputKey,
// 		Group: Group
// 	};
// 	const { status, response } = await makeApiCall(URL, 'POST', data, true, CUSTOMER_INTERACTION_REPORT);
// 	return status ? response?.json() : response;
// };

export const getCustomerInteractionReport = async ({ fromTime = '', toTime = '', searchInputKey = '0', fromLimit = '', toLimit = '', Type = '1', campaignName = '0', Group = '1' }): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1047,
		FromDate: fromTime,
		ToDate: toTime,
		CampaignId: campaignName,
		CallId: searchInputKey,
		Group: Group,
		FromLimit: fromLimit,
		ToLimit: toLimit,
		Type: Type,
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getCallDetailsModalReport = async ({ monitorUCID = '' }): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 100101,
		monitorUCID: monitorUCID
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getIVRHangupsReport = async ({ fromTime = '', toTime = '', campaignName = '0' }): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1013,
		FromDate: fromTime,
		ToDate: toTime,
		CampaignId: campaignName
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getPredictivePerformanceLiveReport = async ({ fromTime = '', toTime = '', campaignName = '0' }): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1019,
		FromDate: fromTime,
		ToDate: toTime,
		CampaignId: campaignName
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getSlaLiveReport = async ({ fromTime = '', toTime = '', campaignName = '0', skill = '0', SlaDuration = '20', SlaPercentage = '80' }): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1020,
		FromTime: fromTime,
		ToTime: toTime,
		CampaignId: campaignName,
		SkillId: skill,
		SlaDuration: SlaDuration,
		SlaPercentage: SlaPercentage
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getPRIUtilisation = async ({ fromTime = '', toTime = '' }): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		FromDate: fromTime,
		ToDate: toTime
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};
export const getVoicemailReport = async ({
	fromTime = '',
	toTime = '',
	day = '1',
	campaignName = '0',
	skill = '0',
	agent = '0',
	phoneName = '0',
	location = '0',
	TransferType = -1,
	UCID = '0',
	CallerId = '',
	callType = '',
	callEvent = 'VoiceMail',
	callStatus = '',
	userID = '7076',
	fromLimit = '',
	toLimit = '',
	Type = "1",
	uui = '',
	disposition = '',
}): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		// apiId: 1001,
		apiId: 1045,
		FromDate: fromTime,
		ToDate: toTime,
		day: day,
		CampaignId: campaignName,
		SkillId: skill,
		AgentId: agent,
		PhoneId: phoneName,
		LocationId: location,
		TransferType: TransferType,
		UCID: UCID,
		UserId: userID,
		CallerId: CallerId,
		CallType: callType,
		Rule: callEvent,
		Status: callStatus,
		FromLimit: fromLimit,
		ToLimit: toLimit,
		Type: Type,
		uui: uui,
		disposition: disposition == "0" ? "" : disposition,
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getDailerCount = async ({ fromTime = '', toTime = '', campaignName = '0', toggle = '1', agent = '0', sortOrderColumn = '', sortOrderType = 0, skill = '0' }): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1003,
		FromTime: fromTime,
		ToTime: toTime,
		CampaignId: campaignName === 'All' ? '0' : campaignName,
		Toggle: toggle,
		AgentId: agent === 'All' ? '0' : agent,
		SkillId: skill,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getCustomerHoldLiveReport = async ({ fromTime = '', toTime = '', CallerNo = '0', agent = '', UCID = '' }): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1018,
		FromDate: fromTime,
		ToDate: toTime,
		CallerNo: CallerNo || 0,
		AgentId: agent,
		UCID: UCID
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getSystemOverviewReport = async ({ fromTime = '', toTime = '', skill = 'All', campaignName = 'All', callType = 'All', reportType = 'All', disabledFilters= '1' }): Promise<any[] | any> => {
	const URL = `${API.REPORTS_URL}/systemOverViewDashboard`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: 1,
		rowsPerPage: 10,
		sortOrderColumn: '',
		sortOrderType: 0,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getSystemOverviewReportFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/systemDashboardFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getAgentPerformanceCallTypeLive = async ({ fromTime = '', toTime = '', campaignName = '0', callType = '', agent = '0' }): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1037,
		FromTime: fromTime,
		ToTime: toTime,
		CampaignId: campaignName,
		CallType: callType,
		AgentId: agent
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getDipositionSummaryLive = async ({ fromTime = '', toTime = '', disposition = '' }): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		FromDate: fromTime,
		apiId: 1040,
		ToDate: toTime,
		isGroup: '0',
		Dispositions: disposition
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getDipositionSummaryLive1 = async ({ page = '', rowsPerPage = '' }): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		pageNo: page,
		rowsPerPage: rowsPerPage
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getAgentLoginLive = async ({ agent = '0', fromTime = '', toTime = '' }): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1039,
		AgentId: agent,
		FromTime: fromTime,
		ToTime: toTime
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getAgentLoginModalTable = async ({ agent = '', loginTime = '', logoutTime = '' }): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 1042,
		AgentID: agent,
		loginTime: loginTime,
		logoutTime: logoutTime
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getSystemAnalyticsFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/systemAnalyticsFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getPerformanceDashboardFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/performanceDashboardFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getSystemAnalysisReport = async ({ fromTime = '', toTime = '', skill = 'All', campaignName = 'All', reportType = 'All',disabledFilters = '1'  }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/systemAnalysisFlow`;
	const data: any = {
		fromTime,
		toTime,
		skill,
		campaignName,
		reportType,
		pageNo: 1,
		rowsPerPage: 10,
		sortOrderColumn: '',
		sortOrderType: 0,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getSystemAnalysisFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/systemAnalysisDashboardFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getAnalysisDashboardReport = async ({ fromTime = '', toTime = '', skill = 'All', campaignName = 'All', callType = 'All', reportType = 'All', disabledFilters= '1' }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/hourlyAnalysisDashboard`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: 1,
		rowsPerPage: 10,
		sortOrderColumn: '',
		sortOrderType: 0,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getAnalysisDashboardFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/analysisDashboardFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getQueueAnalysisReport = async ({ fromTime = '', toTime = '', skill = 'All', campaignName = 'All', reportType = 'All' }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/queueAnalysisFlow`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		pageNo: 1,
		rowsPerPage: 10,
		sortOrderColumn: '',
		sortOrderType: 0,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: 2
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getQueueAnalysisFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/queueAnalysisFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getPhoneDashboardFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/phoneDashBoardFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getAgentPerformanceCallModeLive = async ({ fromTime = '', toTime = '', campaignName = '0', modeType = '100', agent = '0' }): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1038,
		FromTime: fromTime,
		ToTime: toTime,
		CampaignId: campaignName,
		Mode: modeType,
		AgentId: agent
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getMissedCallFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/missedCallFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getPredictivePerformanceFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/predictivePerformanceFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getCampaignProductivityFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/campaignProductivityFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getCustomerHoldFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/customerHoldReportFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getAgentProductivityFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/agentProductivityFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getAgentStateSummaryFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/agentStateSummaryReportFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getAgentCallSummaryFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/agentCallSummaryFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getHourlyActiveAgentReport = async ({ fromTime = '', toTime = '', skill = 'All', campaignName = 'All' }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/hourlyActiveAgents`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		campaignName: campaignName,
		pageNo: 1,
		rowsPerPage: 10,
		sortOrderColumn: '',
		sortOrderType: 0,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: 8
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getHourlyActiveAgentDetailedReport = async ({ fromTime = '', toTime = '', skill = 'All', campaignName = 'All', page = 1, rowsPerPage = 10 }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/hourlyActiveAgentsDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		campaignName: campaignName,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: '',
		sortOrderType: 0,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: 8
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};


export const getDownloadHourlyActiveAgentDetailedReport = async ({ fromTime = '', toTime = '', skill = 'All', campaignName = 'All', page = 1, rowsPerPage = 10, totalRows = 0, requestExcecutionId = null }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullHourlyActiveAgentsDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		campaignName: campaignName,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: '',
		sortOrderType: 0,
		requestExcecutionId: requestExcecutionId,
		totalNoOfRows: totalRows,
		reportType: 'All',
		filterValue: 8
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getHourlyAgentsSummaryFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/hourlyAgentSummaryFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getAgentEfficiencyFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/agentEfficiencyFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getDayCallSummaryFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/daySummaryDynaFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getDispositionCallSummaryReport = async ({
	fromTime = '',
	toTime = '',
	agent = 'All',
	skill = 'All',
	campaignName = 'All',
	callMetrics = '',
	callType = 'All',
	page = '',
	rowsPerPage = '',
	dateFormat = '2',
	isCheckedSkill = false,
	isCheckedCampaign = false,
	isCheckedDate = false,
	isCheckedCallType = false,
	isCheckedAgent = false,
	filters = '0',
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/dispositionSummaryDyna`;
	const data: any = {
		fromTime,
		toTime,
		agent,
		skill,
		campaignName,
		callType,
		callMetrics,
		dateFormat,
		isCheckedSkill,
		isCheckedCampaign,
		isCheckedDate,
		isCheckedCallType,
		isCheckedAgent,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: '',
		sortOrderType: 0,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getDispositionCallSummaryFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/dispositionSummaryDynaFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getChatDispositionSummaryFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/dispositionSummaryDynaFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getSLAFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/serviceLevelAgreementFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getDaywisenumberofcallsFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/dayWiseCallsFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getPortUtilizationDidFilters = async (): Promise<any> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 2002,
		UserId: getUserId(),
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	console.log("status", status)
	console.log("response", response)
	return status ? (response.status === 200 ? response?.json() : []) : [];
};

export const getHourlyPhoneCallFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/hourlyPhoneCallSummaryFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getHourlyCallSummaryFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/hourlyCallSummaryFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getCallSummaryInboundReports = async ({ fromTime = '', toTime = '', skill = '', campaignName = '', reportType = '', disabledFilters= '1' }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/callSummaryReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		skill: skill,
		reportType: reportType,
		pageNo: 1,
		rowsPerPage: 10,
		sortOrderColumn: '',
		sortOrderType: 0,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getCallSummaryInboundDetailedReports = async ({
	fromTime = '',
	toTime = '',
	skill = '',
	campaignName = '',
	reportType = '',
	page = 1,
	rowsPerPage = 10,
	sortOrderColumn = '',
	sortOrderType = 0,
	disabledFilters= '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/callSummaryDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		skill: skill,
		reportType: reportType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadCallSummaryDetailInbound = async ({
	fromTime = '',
	toTime = '',
	skill = '',
	campaignName = '',
	reportType = '',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = '',
	disabledFilters= '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullCallSummaryDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getCallsummaryInboundFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/callSummaryFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getPhoneProductivityFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/phoneProdFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getSkillProductivityFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/skillProductivityFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getConferenceDetailsFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/conferenceFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getTransferedCallsFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/transferredCallsFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getUserHangupIvrFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/userHangUpIVRFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getCallSummaryOurboundFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/callSummaryOutboundFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getAgentBreakFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/agentBreakDetailsReportFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getAgentDispositionFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/agentDispositionFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getAgentLoginReportFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/agentLoginReportFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getHourlyAgentCallFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/agentHourlyCallInboundFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getDigitalDetailFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/chatDetailsReportFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getWhatsappReportFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/WhatsappReportFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getAgentPauseBreakFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/agentCallSummaryFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getCallDetailsFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/CDRV2`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getAgentSummary = async ({
	fromTime = '',
	agent = 'All',
	toTime = '',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	isCheckedAgentDate = false,
	isCheckedAgentMetrics = false,
	isCheckedAgentModeType = false,
	callMetrics = 'All',
	modeType = 'All',
	dateFormat = '1',
	// callMetricsAll = 'All'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/agentSummaryDyna`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		callMetrics,
		modeType,
		agent: agent,
		dateFormat,
		isCheckedAgentDate,
		isCheckedAgentMetrics,
		isCheckedAgentModeType,
		// callMetricsAll,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: 8
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getAgentSummaryFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/agentSummaryDynaFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
}






//MERGED CONTENT

// const BASE_URL = 'http://10.233.17.203:8082';
const BASE_URL = "http://10.233.18.36:8081/CloudAgentAPI"; // for ADMIN




export const getPauseReason = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/pauseReasons`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};
export const getPauseReasonById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/pauseReasons/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();

};
export const saveNewReason = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/pauseReasons`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const addNewReasonById = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/pauseReasons/${data.id}`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};
export const deletePauseReasonById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/pauseReasons/${id}`;
	const response = await fetch(URL, {
		method: "DELETE",
		headers: getAuthHeaders(),
	});
	return response.json();

};

export const getHolidaysList = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/holidayList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getHolidaysListForCampaigns = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/holiday`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getHolidayById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/holiday/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const saveHoliday = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/holiday`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const updateHolidayById = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/holiday/${data.id}`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const deleteHolidayById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/holiday/${id}`;
	const response = await fetch(URL, {
		method: "DELETE",
		headers: getAuthHeaders(),
	});
	return response.json();
};



export const getAgentPhoneNumbers = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/phoneNumbers`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getAgentPhoneNumberById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/phoneNumbers/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const addAgentPhoneNumbers = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/phoneNumbers`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const addAgentPhoneNumbersById = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/phoneNumbers/${data.id}`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const deleteAgentPhoneNumbersById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/phoneNumbers/${id}`;
	const response = await fetch(URL, {
		method: "DELETE",
		headers: getAuthHeaders(),
	});
	return response.json();

};

export const getDialoutNumbers = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/dialOutNumbers`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getDialoutNumberById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/dialOutNumbers/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const addDialoutNumbers = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/dialOutNumbers`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const addDialoutNumbersById = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/dialOutNumbers/${data.id}`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const deleteDialoutNumbersById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/dialOutNumbers/${id}`;
	const response = await fetch(URL, {
		method: "DELETE",
		headers: getAuthHeaders(),
	});
	return response.json();

};

export const getTransferNumbers = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/transfernumbers`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getTransferNumberById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/transfernumbers/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};








export const getUserLoggedInForOTP = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/getLoggedInUser`;

let header =getAuthHeaders();

const mfaToken=localStorage.getItem("mfaToken") || "";
const userId = localStorage.getItem("userId") || ""; 

header.userId =userId;
const mfaTokenValue = mfaToken.replace(/^"|"$/g, '');
header.Authorization="Bearer "+mfaTokenValue;


	const response = await fetch(URL, {
		headers: header,
	});
	return response.json();
};



export const getUserDetailsForOTP = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/getUserModules`;

let header =getAuthHeaders();

const mfaToken=localStorage.getItem("mfaToken") || "";
const userId = localStorage.getItem("userId") || ""; 

header.userId =userId;
const mfaTokenValue = mfaToken.replace(/^"|"$/g, '');
header.Authorization="Bearer "+mfaTokenValue;


	const response = await fetch(URL, {
		headers: header,
	});
	return response.json();
};








export const sendOTP = async (type: string): Promise<any> => {  
  const URL = `${API.ADMIN_BASE_URL}/sendOTP`;

  const reqBody = {
    type: type,
  };
let header =getAuthHeaders();

const mfaToken=localStorage.getItem("mfaToken") || "";
const userId = localStorage.getItem("userId") || ""; 

header.userId =userId;
const mfaTokenValue = mfaToken.replace(/^"|"$/g, '');
header.Authorization="Bearer "+mfaTokenValue;

  const response = await fetch(URL, {
    method: "POST",
    body: JSON.stringify(reqBody),
    headers: header,
  });

  // Assuming the response is an object
  return response.json();
};

export const addTranferNumbers = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/transfernumbers`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const addTranferNumbersById = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/transfernumbers/${data.id}`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const deleteTranferNumbersById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/transfernumbers/${id}`;
	const response = await fetch(URL, {
		method: "DELETE",
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getBlockNumbers = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/blockNumberList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const checkBlockNumberExist = async (blockNumber: any): Promise<any[]> => {
	//change api
	const URL = `${API.ADMIN_BASE_URL}/checkBlockNumberExisted?blockNumber=${blockNumber}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};



export const addBlockNumbers = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/blockNumberUpload`;
	const response = await fetch(URL, {
		method: "POST",
		body: data,
		headers: {
			Authorization: "Bearer " + localStorage.getItem("token"),
			Userid: localStorage.getItem("userId") + ""
		},
	});
	return response.json();
};

export const uploadBlockNumber = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/blockNumberGroupsUpload?isUpdate=${data.isUpdate}`;
	const response = await fetch(URL, {
		method: "POST",
		body: data.body,
		headers: {
			Authorization: "Bearer " + localStorage.getItem("token"),
			Userid: localStorage.getItem("userId") + ""
		},
	});
	return response.json();
};

export const createBlockNumber = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/createBlockNumbers?isUpdate=${data.isUpdate}`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data.body),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const addBlockGroupsAsJson = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/blockNumbers`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const addBlockGroupNew = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/blockGroups`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const updateBlockGroups = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/updateBlockNumbers`;

	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getBlockGroupFilters = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/getBlockNumbersFilters`;
	const response = await fetch(URL, {
		headers: {
			Authorization: "Bearer " + localStorage.getItem("token"),
			Userid: localStorage.getItem("userId") + ""
		},
	});
	return response.status === 200 ? response?.json() : [];
};
export const getBlockGroupData = async ({
	blockNumberIds = '',
	groupIds = '',
	startDate = '',
	endDate = '',
	limit = 10,
	page = 1
}): Promise<any> => {
	const URL = `${API.ADMIN_BASE_URL}/getBlockNumberList?limit=${limit}&page=${page}`;
	const data: any = {
		blockNumberIds: blockNumberIds,
		groupIds: groupIds,
		startDate: startDate,
		endDate: endDate
	};
	console.log("Data sent to API:", data);

	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const deleteBlockNumbers = async (data: any): Promise<any[]> => {
	//change endpoint
	console.log("delete block numbers", data)
	const URL = `${API.ADMIN_BASE_URL}/deleteBlockNumber?blockNumberId=${data.id}`;
	const response = await fetch(URL, {
		method: "DELETE",
		headers: {
			Authorization: "Bearer " + localStorage.getItem("token"),
			userId: `${getUserId()}`,
		}
	});
	return response.status === 200 ? response?.json() : [];
};

export const getBlockGroupById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/getGroupName/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const addBlockGroups = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/blockGroups`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getBlockGroups = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/blockNumberUpload`;
	const response = await fetch(URL, {
		method: "POST",
		body: data,
		headers: {
			Authorization: "Bearer " + localStorage.getItem("token"),
			Userid: localStorage.getItem("userId") + ""
		},
	});
	return response.json();
};

export const updateExpiryDateBlockNumber = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/blockNumbers`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const addBlockGroupsById = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/blockGroups/${data.id}`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};


export const getAgentListForAdmin = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/agents/`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getAgentbyId = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/agents/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
}

export const deleteAgentsById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/agents/${id}`;
	const response = await fetch(URL, {
		method: "DELETE",
		headers: getAuthHeaders(),
	});
	return response.json();
};


export const addNewAgent = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/agents`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const updateAgentDetails = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/agents/${data.id}`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getData = () => {
	const data = [
		{
			QueueSize: 10,
			SkillName: "General",
			Priority: 1,
			QueueTimeOut: "00:00:30",
			Record: "Full",
			id: 36830,
			SkillLocation: "Hyd",
		},
		{
			QueueSize: 5,
			SkillName: "sticky",
			Priority: 1,
			QueueTimeOut: "00:00:00",
			Record: "Full",
			id: 36831,
			SkillLocation: "Hyd",
		},
		{
			QueueSize: 10,
			SkillName: "Resource",
			Priority: 1,
			QueueTimeOut: "00:00:00",
			Record: "Full",
			id: 36832,
			SkillLocation: "Hyd",
		},
		{
			QueueSize: 10,
			SkillName: "Preview1",
			Priority: 1,
			QueueTimeOut: "00:00:00",
			Record: "Full",
			id: 36833,
			SkillLocation: "Hyd",
		},
		{
			QueueSize: 5,
			SkillName: "Preview2",
			Priority: 1,
			QueueTimeOut: "00:00:00",
			Record: "Full",
			id: 36834,
			SkillLocation: "Hyd",
		},
		{
			QueueSize: 3,
			SkillName: "hari_skill",
			Priority: 1,
			QueueTimeOut: "00:01:00",
			Record: "Full",
			id: 36835,
			SkillLocation: "Hyd",
		},
		{
			QueueSize: 5,
			SkillName: "QA_Salman",
			Priority: 1,
			QueueTimeOut: "00:01:02",
			Record: "Full",
			id: 36836,
			SkillLocation: "Hyd",
		},
		{
			QueueSize: 5,
			SkillName: "IVRCheck",
			Priority: 1,
			QueueTimeOut: "00:00:00",
			Record: "Full",
			id: 36838,
			SkillLocation: "Hyd",
		},
		{
			QueueSize: 5,
			SkillName: "Balraj_Skill",
			Priority: 1,
			QueueTimeOut: "00:00:00",
			Record: "Full",
			id: 36839,
			SkillLocation: "Hyd",
		},
		{
			QueueSize: 3,
			SkillName: "Balu_Skill",
			Priority: 1,
			QueueTimeOut: "00:01:01",
			Record: "Full",
			id: 36840,
			SkillLocation: "Hyd",
		},
		{
			QueueSize: 5,
			SkillName: "Predictive",
			Priority: 1,
			QueueTimeOut: "00:00:00",
			Record: "Full",
			id: 36841,
			SkillLocation: "Hyd",
		},
		{
			QueueSize: 10,
			SkillName: "Skill_Vathi",
			Priority: 1,
			QueueTimeOut: "00:00:00",
			Record: "Full",
			id: 36842,
			SkillLocation: "Hyd",
		},
		{
			QueueSize: 3,
			SkillName: "shankerpally",
			Priority: 1,
			QueueTimeOut: "00:00:00",
			Record: "Full",
			id: 36843,
			SkillLocation: "Hyd",
		},

		{
			QueueSize: 1,
			SkillName: "hh",
			Priority: 1,
			QueueTimeOut: "00:00:00",
			Record: "Full",
			id: 36863,
			SkillLocation: "Hyd",
		},
		{
			QueueSize: 1,
			SkillName: "chennakeshavareddy",
			Priority: 1,
			QueueTimeOut: null,
			Record: "Full",
			id: 36864,
			SkillLocation: "Hyd",
		},
	];

	return data;
};
export const getEmail = () => {
	const data = [
		{
			TemplateName: "Campaign Test",
			Subject: "Lorem ipsum dolor si",
			From: "dolores.chambers@example.com",
		},
		{
			TemplateName: "Whatsapp",
			Subject: "Lorem ipsum dolor si",
			From: "dolores.chambers@example.com",
		},
	];
	return data;
};
export const getSMS = () => {
	const data = [
		{
			TemplateName: "Campaign Test",
			TemplateText: "Lorem ipsum dolor si",
		}, {
			TemplateName: " Test",
			TemplateText: "dolor si",
		}, {
			TemplateName: "Campaign ",
			TemplateText: "Lorem ",
		},
	];
	return data;
};
export const getWhatsapp = () => {
	const data = [
		{
			TemplateName: "Campaign Test",
			TemplateText: "Lorem ipsum dolor si",
		},
	];
	return data;
};
export const getEmailTemplate = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/emailTemplate`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};
export const getEmailTemplateById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/emailTemplate/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();

};
export const addEmailTemplate = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/emailTemplate`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const addEmailTemplateById = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/emailTemplate/${data.id}`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};
export const deleteEmailTemplateById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/emailTemplate/${id}`;
	const response = await fetch(URL, {
		method: "DELETE",
		headers: getAuthHeaders(),
	});
	console.log(response.json())
	return response.json();

};
export const getSkillFlow = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/skills`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
}
export const getSkillById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/skills/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();

};
export const addSkill = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/skills`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const updateSkillById = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/skills/${data.id}`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};
export const deleteSkillById = async (Id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/skills/${Id}`;
	const response = await fetch(URL, {
		method: "DELETE",
		headers: getAuthHeaders(),
	});
	return response.json();

};



export const getSkillsDropdown = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/skillList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getAvailableSipNumbers = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/sipList/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getSecondaryDidDropdown = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/secondaryDID/list`;
	const response = await fetch(URL, {
		method: "POST",
		headers: getAuthHeaders(),
		body: JSON.stringify({})
	});
	return response.json();

};

export const getIvrFlowDropdown = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/ivrFlowList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getIvrFlowChatDropdown = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/icrFlowList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getSubUserDropdown = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/subUserList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getBlockNumberGroupDropdown = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/blockGroupList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.status === 200 ? response?.json() : [];
};

export const getOnHoldMusicDropdown = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/musicFileList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getPreviewDataDropdown = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/previewMapList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getCampaignListForAdmin = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/campaigns?campaignType=inbound`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
	// return dummyCampaignrowDatra;
};

export const getMarketingCampaignListForAdmin = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/campaigns?campaignType=marketing`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
	// return dummyCampaignrowDatra;
};

export const addNewMarketingCampaign = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/missedCallCampaign`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
	// return dummyAgentrowDatra;
};

export const addNewMapping = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/previewDataMap`;
	const response = await fetch(URL, {
		method: "POST",
		body: data,
		headers: {
			Authorization: "Bearer " + localStorage.getItem("token"),
			userId: `${getUserId()}`,
		}
	});
	return response.json();
};

export const addNewUploadFile = async (data: any, id: any): Promise<any[]> => { //current Limit is 50000 rows
	const URL = `${API.ADMIN_BASE_URL}/campaignUpload/${id}`;
	const response = await fetch(URL, {
		method: "POST",
		body: data,
		headers: {
			Authorization: "Bearer " + localStorage.getItem("token"),
			userId: `${getUserId()}`,
		}
	});
	return response.json();
};

export const addNewMarketingFormDigitalCampaign = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/digitalCampaign`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
	// return dummyAgentrowDatra;
};

export const updateMarketingCampaign = async (data: any, id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/missedCallCampaign/${id}`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
	// return dummyAgentrowDatra;
};

export const updateMarketingDigitalCampaign = async (data: any, id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/digitalCampaign/${id}`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
	// return dummyAgentrowDatra;
};

export const getDispositionsDropdown = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/dispositions`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getDidDropdown = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/secondaryDID/list`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const updatePostCallwork = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/save/callConfig`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const addPostCallwork = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/save/callConfig`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const deletePostCallwork = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/save/callConfig`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getAddCallWork = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/callFlowConfig`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getPushDataMarketingDropdown = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/campaigns?campaignType=pushData`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getSendSmsMarketingDropdown = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/campaigns?campaignType=sms`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getPluginDropdown = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/plugins`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getRestoreCampaignListForAdmin = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/restoreCampaigns`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
	// return dummyCampaignrowDatra;
};

export const getDownloadDataCampaignListForAdmin = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/campaignData/downloadCampaignsList`;
	const response = await fetch(URL, {
		method: 'POST',
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const restoreById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/restoreCampaigns/${id}`;
	const response = await fetch(URL, {
		method: 'PUT',
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const startStopCampaignById = async (id: any, data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/campaignPosition/${id}`;
	const response = await fetch(URL, {
		method: 'PUT',
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const forceCompleteCampaignById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/forceComplete/${id}`;
	const response = await fetch(URL, {
		method: 'PUT',
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const downloadOutboundCampaign = async (data: any, id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/campaignDownloadData/${id}`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const downloadFormCampaignData = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/campaignDownloadDataList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const disableIntegrationById = async (id: any): Promise<any[]> => {
	// const URL = `http://10.233.18.36:8081/CloudAgentAPI/disableIntegrationById/921`;
	const URL = `${API.ADMIN_BASE_URL}/disableIntegrationById/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const enableIntegrationById = async (id: any): Promise<any[]> => {
	// const URL = `http://10.233.18.36:8081/CloudAgentAPI/enableIntegrationById/917`;
	const URL = `${API.ADMIN_BASE_URL}/enableIntegrationById/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const deleteCampaignInbound = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/inboundCampaign/${id}`;
	const response = await fetch(URL, {
		method: 'DELETE',
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getDIDInMissedCallDropdown = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/missedCallDIDs`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const deleteCampaignOutbound = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/outboundCampaign/${id}`;
	const response = await fetch(URL, {
		method: 'DELETE',
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const deleteChatCampaign = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/digitalCampaign/${id}`;
	const response = await fetch(URL, {
		method: 'DELETE',
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const deleteMissedCallCampaign = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/missedCallCampaign/${id}`;
	const response = await fetch(URL, {
		method: 'DELETE',
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getCampaignOutboundListForAdmin = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/campaigns?campaignType=outbound`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getCampaignsListForAdmin = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/campaigns?campaignType=outbound`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getRowDataListForCampaignId = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/campaigns/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const addNewCampaignInbound = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/inboundCampaign`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
	// return dummyAgentrowDatra;
};

export const updateCamapignInbound = async (data: any, id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/inboundCampaign/${id}`;
	const response = await fetch(URL, {
		method: 'PUT',
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
	// return dummyAgentrowDatra;
};

export const getBulkCampaignDataDownload = async (data: any, id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/campaignData/campaignDownloadData`;
	const response = await fetch(URL, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const updateBulkCampaign = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/outBoundCampaignManagement`;
	const response = await fetch(URL, {
		method: 'PUT',
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const addNewCampaignOutbound = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/outBoundCampaign`;
	const response = await fetch(URL, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
	// return dummyAgentrowDatra;
};

export const updateCamapignOutbound = async (data: any, id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/outBoundCampaign/${id}`;
	const response = await fetch(URL, {
		method: 'PUT',
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
	// return dummyAgentrowDatra;
};

export const getsubadmin = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/users`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};


export const getsubUserProfileList = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/profile/list`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getProfileById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/profile/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const deleteProfileById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/profile/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
		method: 'DELETE'
	});
	return response.json();
};

export const saveAndUpdateUserProfile = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/profile/save`;
	const response = await fetch(URL, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getFeatureListRoles = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/profile/${getUserId()}/features-roles`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const addsubadmin = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/users`;
	const response = await fetch(URL, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const updatesubadminById = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/users/${data.id}`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const deletesubadminById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/users/${id}`;
	const response = await fetch(URL, {
		method: "DELETE",
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getsubadminById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/users/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const gettimezoneDropdown = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/timezoneList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getSipBargeExtensionDropdown = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/getBargeSip`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getPartnerLevelDetails = async (accountId: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/subAccountList/${accountId}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getPartnerLevelForUser = async (username: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/accountList/${username}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getMapToServerList = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/urlMapList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getDialerMappingList = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/dialerMappingList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getWhatsappData = () => {
	const tempdata = [
		{
			Template_Name: "ZohoUser ",
			Template_Text: "Desgin",
		},
		{
			Template_Name: "ZohoUser1 ",
			Template_Text: "Desgin",
		},
		{
			Template_Name: "ZohoUser2 ",
			Template_Text: "Desgin",
		},
	];
	return tempdata;
};

export const getLocation = () => {
	const tempData = [
		{ Name: "India", NoOfSkills: "2" },
		{ Name: "China", NoOfSkills: "6" },
	];

	return tempData;
};

export const getDataSummary = async ({ campaiginType = 'All', filters = '0' }): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 129,
		campaignId: filters,
		CampaignType: campaiginType
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const outboundCampaignStatusLive = async ({ agent = '0', campaign = '0', toggle = '0', skill = '0', campaignType = 'All' }) => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 131,
		AgentID: agent,
		campaignId: campaign,
		ToggleFlag: toggle,
		SkillID: skill,
		CampaignType: campaignType
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getOutboundMonitorPacing = async ({ campaignType = 'Predictive', campaignId = 0 }): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 118,
		campaignId: campaignId,
		campaignType: campaignType
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getDropRatio = async ({ campaignType = 'Predictive', campaignId = 0 }): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 117,
		campaignId: campaignId,
		campaignType: campaignType
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
}

export const getIvrFlow = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/ivrFlows`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const addNewIvr = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/ivrFlows`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();

};


export const saveCopyIvr = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/saveCopyIvr`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();

};

export const saveCopyAccountIvr = async (data: any, accountId: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/saveCopyAccountIvr/${accountId}`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const updateIvrDetails = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/ivrFlows/${data.flowId}`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const deleteIvrById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/ivrFlows/${id}`;
	const response = await fetch(URL, {
		method: "DELETE",
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getIvrById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/ivrFlows/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getAgentNames = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/agentBreakDetailsReportFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : [];
};

export const reassignAgentCallback = async ({ callBackID = '3001324', callBackDate = '2023-01-28 23:59:59', agentId = '' }): Promise<any> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1035,
		callBackID,
		action: 'Update',
		agentId,
		callBackDate
	};
	const { response } = await makeApiCall(URL, 'POST', data, true);
	return response;
};

export const deleteAgentCallback = async ({ callBackID = '' }): Promise<any> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1036,
		callBackID,
		action: 'Delete',
		CallBackIsDeleted: 1
	};
	const { response } = await makeApiCall(URL, 'POST', data, true);
	return response;
};

export const getPerformanceAnalyticsReport = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	agent = 'All',
	rowsPerPage = 10,
	sortOrderColumn = '',
	sortOrderType = 0,
	totalNoOfRows = 0,
	requestExcecutionId = null,
	pageNo = 1,
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/top3PerformerAnalyticsReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		agent: agent,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: pageNo,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: requestExcecutionId,
		totalNoOfRows: totalNoOfRows,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getPerformanceAnalyticsFilters = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/top3PerformerFilters`;
	const { status, response } = await makeApiCall(URL, 'GET');
	return status ? response?.json() : response;
};

export const getAgentOnPauseLiveCount = async (): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 100601,
		Type: '0',
		FilterId: '0'
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getAgentOnPauseLiveDetails = async (): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 100602,
		Type: '0',
		FilterId: '0',
		Limit: '4'
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getCallDetailsDumpLiveReport = async ({
	fromTime = '',
	toTime = '',
	UCID = '0',
	monitorUCID = '0',
	campaignName = '0',
	transfered = -1,
	Rule = '',
	CallerId = '',
	callType = '',
	callStatus = '',
	day = '1',
	skill = '0',
	agent = '0',
	phoneName = '0',
	location = '0',
	Type = "1",
	userID = '7076',
	fromLimit = '',
	toLimit = '',
	uui = '',
	disposition = '',
}): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;

	const data: any = {
		apiId: 1046,
		FromDate: fromTime,
		ToDate: toTime,
		UCID: UCID,
		MonitorUCID: monitorUCID,
		TransferType: transfered,
		CampaignId: campaignName || 0,
		Rule: Rule,
		CallerId: CallerId,
		CallType: callType,
		Status: callStatus,
		day: day,
		SkillId: skill,
		AgentId: agent,
		PhoneId: phoneName,
		LocationId: location,
		Type: Type,
		UserId: userID,
		FromLimit: fromLimit,
		ToLimit: toLimit,
		uui: uui,
		disposition: disposition == "0" ? "" : disposition,
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

// export const getCustomerInteractionDetailedReport = async ({ fromTime = '', toTime = '', searchInputKey = '0', campaignName = '0', Group = '0' }): Promise<any[]> => {
// 	const URL = `${API.LIVE_API_URL}/monitor/api`;
// 	const data: any = {
// 		apiId: 1011,
// 		FromDate: fromTime,
// 		ToDate: toTime,
// 		CampaignId: campaignName,
// 		CallId: searchInputKey,
// 		Group: Group
// 	};
// 	const { status, response } = await makeApiCall(URL, 'POST', data, true, CUSTOMER_INTERACTION_REPORT);
// 	return status ? response?.json() : response;
// };

export const getCustomerInteractionDetailedReport = async ({ fromTime = '', toTime = '', searchInputKey = '0', fromLimit = '', toLimit = '', Type = '1', campaignName = '0', Group = '0' }): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1047,
		FromDate: fromTime,
		ToDate: toTime,
		CampaignId: campaignName,
		CallId: searchInputKey,
		Group: Group,
		FromLimit: fromLimit,
		ToLimit: toLimit,
		Type: Type,
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};
export const getCustomerInteractionDrillDown = async ({ fromTime = '', toTime = '', searchInputKey = '0', fromLimit = '0', toLimit = '100', Type = '1', campaignName = '0', Group = '0' }): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1047,
		FromDate: fromTime,
		ToDate: toTime,
		CampaignId: campaignName,
		CallId: searchInputKey,
		Group: Group,
		FromLimit: fromLimit,
		ToLimit: toLimit,
		Type: Type,
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const downloadHourlyCallSummaryReport = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	totalRows = '',
	disabledFilters= '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullHourlyCallSummaryReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: '',
		sortOrderType: 0,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, false);
	return status ? response?.json() : response;
};

export const downloadHourlyCallSummaryReportDetails = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	totalRows = '',
	disabledFilters= '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullHourlyCallSummaryDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: '',
		sortOrderType: 0,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, false);
	return status ? response?.json() : response;
};

export const downloadHourlyPhoneCallReports = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	phone = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = '',
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullHourlyPhoneCallSummaryReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		phone: phone,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadHourlyPhoneCallDetailedReports = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	phone = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = '',
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullHourlyPhoneCallSummaryDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		phone: phone,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadPhoneProductivityReports = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	reportType = 'All',
	callType = 'All',
	phoneNumber = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = '',
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullPhoneProductivityReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		skill: skill,
		reportType: reportType,
		phoneNumber: phoneNumber,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, false);
	return status ? response?.json() : response;
};

export const downloadPhoneProductivityDetailedReports = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	reportType = 'All',
	callType = 'All',
	phoneNumber = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = '',
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullPhoneProductivityDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		skill: skill,
		reportType: reportType,
		phoneNumber: phoneNumber,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, false);
	return status ? response?.json() : response;
};

export const downloadSkilsProductivityReports = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = ''
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullSkillProductivityReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		skill: skill,
		reportType: reportType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: 2
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadSkilsProductivityDetailedReports = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = ''
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullSkillProductivityDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		skill: skill,
		reportType: reportType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: 2
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadCampaignProductivityReports = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = ''
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullCampaignProductivityReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: 1
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadCampaignProductivityDetailedReports = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = ''
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullCampaignProductivityDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: 1
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadConferenceDetails = async ({ fromTime = '', toTime = '', agent = 'All', page = '', rowsPerPage = '', sortOrderColumn = '', sortOrderType = 0, totalRows = '' }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullConferenceDetailsReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		agent: agent,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue:8
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadCustomerHoldReport = async ({
	fromTime = '',
	toTime = '',
	agent = 'All',
	callerNo = 'All',
	page = '1',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = ''
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullCustomerHoldReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		agent: agent,
		callerNo: callerNo,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: 8
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadIVRFeedbackReport = async ({
	fromTime = '',
	toTime = '',
	campaignName = '',
	page = 1,
	rowsPerPage = 10,
	sortOrderColumn = '',
	sortOrderType = 0,
	requestExcecutionId = null,
	totalRows = 0
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullIVRFeedbackReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: requestExcecutionId,
		totalNoOfRows: totalRows,
		filterValue: 1
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, false);
	return status ? response?.json() : response;
};

export const downloadMissedCallReport = async ({
	fromTime = '',
	toTime = '',
	page = 1,
	rowsPerPage = 10,
	sortOrderColumn = '',
	sortOrderType = 0,
	requestExcecutionId = null,
	totalRows = 0
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullmissedCallReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: requestExcecutionId,
		totalNoOfRows: totalRows,
		filterValue: 2
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadPredictivePerformanceReport = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = ''
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullPredictivePerformanceReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: 1
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadSystemSummaryReport = async ({ fromTime = '', toTime = '', sortOrderColumn = '', sortOrderType = 0 }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullSystemSummaryReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		pageNo: 1,
		rowsPerPage: 10,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: 1
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadTransferredCallsReport = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	agent = 'All',
	reportType = 'All',
	transfered = 'All',
	phoneName = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = '',
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullTransferredCallsReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		reportType: reportType,
		skill: skill,
		agent: agent,
		transfered: transfered,
		phoneName: phoneName,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadUserHangupInIvr = async ({
	fromTime = '',
	toTime = '',
	campaignName = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = ''
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullUserHangUpIVRReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: 1
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadAuditLogReport = async ({ fromTime = '', toTime = '', page = '', rowsPerPage = '', sortOrderColumn = '', sortOrderType = 0, totalRows = '' }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullAuditLogReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadCallSummaryOutbound = async ({
	fromTime = '',
	toTime = '',
	callType = 'All',
	campaignName = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = ''
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullCallSummaryOutboundReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: 1
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadCallSummaryOutboundDetailedReports = async ({
	fromTime = '',
	toTime = '',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = ''
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullCallSummaryOutboundDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: 1
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadAgentBreakDetailsReport = async ({
	fromTime = '',
	toTime = '',
	agent = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = ''
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullAgentBreakReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		agent: agent,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		filterValue: 8
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadAgentBreakDetailsDetailedReport = async ({
	fromTime = '',
	toTime = '',
	agent = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = ''
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullAgentBreakDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		agent: agent,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		filterValue: 8
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadAgentDispositionReport = async ({
	fromTime = '',
	toTime = '',
	agent = 'All',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = '',
	disabledFilters = '8'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullAgentDispositionReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		agent: agent,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadAgentDispositionDetailedReport = async ({
	fromTime = '',
	toTime = '',
	agent = 'All',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = '',
	disabledFilters = '8'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullAgentDispositionDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		agent: agent,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadAgentLoginReports = async ({ fromTime = '', toTime = '', event = 'All', agent = 'All', page = '', rowsPerPage = '', sortOrderColumn = '', sortOrderType = 0, totalRows = '' }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullAgentLoginReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		agent: agent,
		event: event,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: 8
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadAgentProdcutivity = async ({
	fromTime = '',
	toTime = '',
	agent = 'All',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = '',
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullAgentProductivityReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		campaignName: campaignName,
		callType: callType,
		agent: agent,
		reportType: reportType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadAgentProductivityDetailedReports = async ({
	fromTime = '',
	toTime = '',
	agent = 'All',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = '',
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullAgentProductivityDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		campaignName: campaignName,
		callType: callType,
		agent: agent,
		reportType: reportType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadAgentStateSummaryReport = async ({
	fromTime = '',
	toTime = '',
	agent = 'All',
	mode = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = ''
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullAgentStateSummaryReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		agent: agent,
		mode: mode,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: 8
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadAgentStateSummaryDetailedReport = async ({
	fromTime = '',
	toTime = '',
	agent = 'All',
	mode = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = ''
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullAgentStateSummaryDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		agent: agent,
		mode: mode,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: 8
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadHourlyAgentCallReport = async ({
	fromTime = '',
	toTime = '',
	agent = 'All',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = '',
	disabledFilters = '8'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullAgentHourlyCallInBoundReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		agent: agent,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadHourlyAgentCallDetailedReport = async ({
	fromTime = '',
	toTime = '',
	agent = 'All',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = '',
	disabledFilters = '8'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullAgentHourlyCallInBoundDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		agent: agent,
		skill: skill,
		campaignName: campaignName,
		reportType: reportType,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadDigitalDetailReport = async ({
	fromTime = '',
	toTime = '',
	campaignName = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = ''
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullChatDetailReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		uui: '',
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: 1
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadAgentSummary = async ({ fromTime = '', toTime = '', requestExcuteId = null, agent = "All", callMetrics = "All", modeType = "All", dateFormat = "1", isCheckedAgentDate = false, isCheckedAgentMetrics = false, isCheckedAgentModeType = false, page = '', rowsPerPage = '', sortOrderColumn = '', sortOrderType = 0, totalRows = '' }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullAgentSummaryDyna`;
	const data: any = {
		agent: agent,
		fromTime: fromTime,
		toTime: toTime,
		callMetrics: callMetrics,
		modeType: modeType,
		dateFormat: dateFormat,
		isCheckedAgentDate: isCheckedAgentDate,
		isCheckedAgentMetrics: isCheckedAgentMetrics,
		isCheckedAgentModeType: isCheckedAgentModeType,
		// callMetricsAll: 'All',
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: requestExcuteId,
		totalNoOfRows: totalRows,
		filterValue: 8
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadDayCallSummaryReports = async ({
	fromTime = '',
	toTime = '',
	// callMetrics = 'All',
	callType = 'All',
	dateFormat = '1',
	campaignName = 'All',
	skill = 'All',
	agent = 'All',
	phoneName = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = '',
	filters = '0',
	isCheckedAgent = false,
	isCheckedCalltype = false,
	isCheckedCampaigname = false,
	isCheckedPhonename = false,
	isCheckedSkill = false,
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullDaySummaryDyna`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		// callMetrics: callMetrics,
		callType: callType,
		dateFormat: dateFormat,
		campaignName: campaignName,
		skill: skill,
		agent: agent,
		phoneName: phoneName,
		isCheckedCampaignDay: isCheckedCampaigname,
		isCheckedSkillDay: isCheckedSkill,
		isCheckedCallTypeDay: isCheckedCalltype,
		isCheckedPhnoDay: isCheckedPhonename,
		isCheckedAgentDay: isCheckedAgent,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: disabledFilters
	};

	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadDispositionCallSummaryReport = async ({
	fromTime = '',
	toTime = '',
	agent = 'All',
	skill = 'All',
	campaignName = 'All',
	callMetrics = '',
	callType = 'All',
	page = '',
	rowsPerPage = '',
	dateFormat = '2',
	isCheckedSkill = false,
	isCheckedCampaign = false,
	isCheckedDate = false,
	isCheckedCallType = false,
	isCheckedAgent = false,
	totalRows = '',
	disabledFilters = '1'
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullDispositionSummaryDyna`;
	const data: any = {
		fromTime,
		toTime,
		agent,
		skill,
		campaignName,
		callType,
		callMetrics,
		dateFormat,
		isCheckedSkill,
		isCheckedCampaign,
		isCheckedDate,
		isCheckedCallType,
		isCheckedAgent,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: '',
		sortOrderType: 0,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: disabledFilters
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadChatDispositionSummary = async ({ fromTime = '', toTime = '', sortOrderColumn = '', sortOrderType = 0, totalRows = '' }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullchatDispositionReport`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		pageNo: 1,
		rowsPerPage: 10,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: 1
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadAgentCallSummary = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	agent = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = ''
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullAgentCallSummary`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		agent: agent,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: 8
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const downloadAgentCallDetailedSummary = async ({
	fromTime = '',
	toTime = '',
	skill = 'All',
	agent = 'All',
	callType = 'All',
	reportType = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	totalRows = ''
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/fullAgentCallSummaryDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		skill: skill,
		agent: agent,
		callType: callType,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: totalRows,
		filterValue: 8
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
}

export const getdisposition = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/dispositions`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getDispositionById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/dispositions/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();

};

export const adddisposition = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/dispositions`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const updatedispositionById = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/dispositions/${data.id}`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const deletedispositionById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/dispositions/${id}`;
	const response = await fetch(URL, {
		method: "DELETE",
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getgroups = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/agentGroups`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const addgroup = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/agentGroups`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const updategroupById = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/agentGroups/${data.id}`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const deletegroupById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/agentGroups/${id}`;
	const response = await fetch(URL, {
		method: "DELETE",
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getgroupById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/agentGroups/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

// export const getagentsDropdown = async (): Promise<any[]> => {
// 	const URL = `${API.ADMIN_BASE_URL}/agentList`;
// 	const response = await fetch(URL, {
// 		headers: getAuthHeaders(),
// 	});
// 	return response.json();
// };

// export const getagentsDropdown = async (): Promise<any> => {
// 	const URL = `${API.LIVE_API_URL}/monitor/api`;
// 	const data: any = {
// 		apiId: 2001
// 	};
// 	const { status, response } = await makeApiCall(URL, 'POST', data, true);
// 	return status ? response?.json() : response;
// };

export const getagentsDropdown = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/getagentsDropdown`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getSipAgentsDropdown = async (): Promise<any> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 2003
	};
	const { status, response } = await makeApiCallWithParentUser(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getSipAgentsDropdownWithId = async (locationId: any): Promise<any> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 112111112,
		LocationId: locationId,
	};
	const { status, response } = await makeApiCallWithParentUser(URL, 'POST', data, true);
	return status ? response?.json() : response;
};


export const getsubUserDropdown = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/subUserList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getConferenceReportLive = async ({ fromTime = '', toTime = '', agent = '0', fromLimit = '', toLimit = '', Type = "1" }): Promise<any> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 1049,
		AgentId: agent,
		FromDate: fromTime,
		ToDate: toTime,
		FromLimit: fromLimit,
		ToLimit: toLimit,
		Type: Type,
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

// export const getConferenceReportLive = async ({ fromTime = '', toTime = '', agent = '0' }): Promise<any> => {
// 	const URL = `${API.LIVE_API_URL}/monitor/api`;
// 	const data: any = {
// 		apiId: 1012,
// 		AgentId: agent,
// 		FromDate: fromTime,
// 		ToDate: toTime
// 	};
// 	const { status, response } = await makeApiCall(URL, 'POST', data, true);
// 	return status ? response?.json() : response;
// };

export const getDigitalDetailsModalReport = async ({
	fromTime = '',
	toTime = '',
	campaignName = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	monitorUCID = ''
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/chatViewDetails`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		uui: '',
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: null,
		totalNoOfRows: 0,
		monitorUCID: monitorUCID
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const downloadCDR = async ({
	fromTime = '',
	UCID = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	phoneName = 'All',
	location = 'All',
	reportType = 'All',
	callStatus = 'All',
	transfered = 'All',
	agent = 'All',
	callEvent = 'All',
	disposition = 'All',
	durationinsecs = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	callID = '',
	callerNo = '',
	durationInput = '',
	reportExportType = 'xls',
	requestExcecutionId = null,
	totalRows = 0,
	reportDownName = "Call Details Report Generate",
	uui = '',
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/asynchronous/fullCallDetailsDumpS3`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		phoneName: phoneName,
		skill: skill,
		agent: agent,
		location: location,
		callID: callID,
		UCID: UCID,
		fallbackRule: '',
		type: '',
		callStatus: callStatus,
		transfered: transfered,
		callType: callType,
		callEvent: callEvent,
		disposition: disposition,
		reportType: reportType,
		durationinsecs: durationinsecs,
		callerNo: callerNo,
		durationInput: durationInput,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: requestExcecutionId,
		totalNoOfRows: totalRows,
		isCallDump: false,

		reportExportType: reportExportType,
		reportDownName: reportDownName,
		uui: uui,
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response : response;
};

export const downloadCDRLegacy = async ({
	fromTime = '',
	UCID = '',
	toTime = '',
	skill = 'All',
	campaignName = 'All',
	callType = 'All',
	phoneName = 'All',
	location = 'All',
	reportType = 'All',
	callStatus = 'All',
	transfered = 'All',
	agent = 'All',
	callEvent = 'All',
	disposition = 'All',
	durationinsecs = 'All',
	page = '',
	rowsPerPage = '',
	sortOrderColumn = '',
	sortOrderType = 0,
	callID = '',
	callerNo = '',
	durationInput = '',
	reportExportType = 'xls',
	requestExcecutionId = null,
	totalRows = 0,
	reportDownName = "Call Details Report Generate",
	uui = '',
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/asynchronous/fullMigrationCallDetailsDumpS3`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		phoneName: phoneName,
		skill: skill,
		agent: agent,
		location: location,
		callID: callID,
		UCID: UCID,
		fallbackRule: '',
		type: '',
		callStatus: callStatus,
		transfered: transfered,
		callType: callType,
		callEvent: callEvent,
		disposition: disposition,
		reportType: reportType,
		durationinsecs: durationinsecs,
		callerNo: callerNo,
		durationInput: durationInput,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: requestExcecutionId,
		totalNoOfRows: totalRows,
		isCallDump: false,

		reportExportType: reportExportType,
		reportDownName: reportDownName,
		uui: uui,
	};

	const response = await fetch(URL, { method: 'POST', body: JSON.stringify(data), headers: getAuthHeadersLegacy() });
	return response;
};

export const downloadCallDetailsDetailedReport = async ({
	fromTime = '',
	toTime = '',
	campaignName = 'All',
	phoneName = 'All',
	skill = 'All',
	agent = 'All',
	location = 'All',
	callID = '',
	UCID = '',
	fallbackRule = '',
	type = '',
	callStatus = 'All',
	transfered = 'All',
	callType = 'All',
	callEvent = 'All',
	disposition = 'All',
	reportType = 'All',
	durationinsecs = 'All',
	callerNo = '',
	durationInput = '',
	page = 1,
	rowsPerPage = 10,
	sortOrderColumn = '',
	sortOrderType = 0,
	requestExcecutionId = null,
	totalRows = 0,
	reportExportType = 'xls',
	reportDownName = "Call Details Report Dump",
	uui = '',
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/asynchronous/fullCallDetailsDumpS3`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		phoneName: phoneName,
		skill: skill,
		agent: agent,
		location: location,
		callID: callID,
		UCID: UCID,
		fallbackRule: fallbackRule,
		type: type,
		callStatus: callStatus,
		transfered: transfered,
		callType: callType,
		callEvent: callEvent,
		disposition: disposition,
		reportType: reportType,
		durationinsecs: durationinsecs,
		callerNo: callerNo,
		durationInput: durationInput,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: requestExcecutionId,
		totalNoOfRows: totalRows,
		isCallDump: true,
		reportExportType: reportExportType,
		reportDownName: reportDownName,
		uui: uui,
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	// return status ? response?.json() : response;
	return status ? response : response;
};

export const downloadCallDetailsDetailedLegacyReport = async ({
	fromTime = '',
	toTime = '',
	campaignName = 'All',
	phoneName = 'All',
	skill = 'All',
	agent = 'All',
	location = 'All',
	callID = '',
	UCID = '',
	fallbackRule = '',
	type = '',
	callStatus = 'All',
	transfered = 'All',
	callType = 'All',
	callEvent = 'All',
	disposition = 'All',
	reportType = 'All',
	durationinsecs = 'All',
	callerNo = '',
	durationInput = '',
	page = 1,
	rowsPerPage = 10,
	sortOrderColumn = '',
	sortOrderType = 0,
	requestExcecutionId = null,
	totalRows = 0,
	reportExportType = 'xls',
	reportDownName = "Call Details Report Dump",
	uui = '',
}): Promise<any> => {
	const URL = `${API.REPORTS_URL}/asynchronous/fullMigrationCallDetailsDumpS3`;
	const data: any = {
		fromTime: fromTime,
		toTime: toTime,
		campaignName: campaignName,
		phoneName: phoneName,
		skill: skill,
		agent: agent,
		location: location,
		callID: callID,
		UCID: UCID,
		fallbackRule: fallbackRule,
		type: type,
		callStatus: callStatus,
		transfered: transfered,
		callType: callType,
		callEvent: callEvent,
		disposition: disposition,
		reportType: reportType,
		durationinsecs: durationinsecs,
		callerNo: callerNo,
		durationInput: durationInput,
		pageNo: page,
		rowsPerPage: rowsPerPage,
		sortOrderColumn: sortOrderColumn,
		sortOrderType: sortOrderType,
		requestExcecutionId: requestExcecutionId,
		totalNoOfRows: totalRows,
		isCallDump: true,
		reportExportType: reportExportType,
		reportDownName: reportDownName,
		uui: uui,
	};

	const response = await fetch(URL, { method: 'POST', body: JSON.stringify(data), headers: getAuthHeadersLegacy() });
	return response;
};

export const getAgentDrawerDeets = async (params = { type: 0, filters: '0', agentId: '0' }): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 116,
		Type: 1,
		FilterId: params.filters,
		AgentID: params.agentId
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getComparativeCallTrendsDrawerDeets = async ({ filters = '0', type = '1', callType = 'All', dateTypeId = 0, hour = '0', Flag = 0 }) => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 122,
		Type: type,
		FilterId: filters,
		CallType: callType,
		DateTypeId: dateTypeId,
		Hour: hour,
		Flag: Flag
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getDigitalDetailsLiveModalReport = async ({ monitorUCID = '' }): Promise<any[]> => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 101401,
		monitorUCID: monitorUCID
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getChatHistoryDetails = async ({ monitorUCID = '', SessionId = '' }) => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 101402,
		monitorUCID: monitorUCID,
		SessionId: SessionId,
		UserId: getUserId()
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const getChatStatusHistoryDetails = async ({ SessionId = '' }) => {
	const URL = `${API.LIVE_API_URL}/monitor/api`;
	const data: any = {
		apiId: 101403,
		SessionId: SessionId,
		UserId: getUserId()
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

//::::::::::::::::::::::::://
export const getSipNumbers = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/endpoint/sipnumber/list`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const saveSipNumber = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/endpoint/data/bulk/single/sipnumbers`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const updateSipNumberById = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/endpoint/data/bulk/single/sipnumbers`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getSipNumberById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/endpoint/sipnumber/list/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const deleteSipNumberById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/endpoint/sipnumber/delete/${id}`;
	const response = await fetch(URL, {
		method: "DELETE",
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const importBulkSipNumbers = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/endpoint/data/bulk/sipnumbers`;
	const response = await fetch(URL, {
		method: "POST",
		body: data,
		headers: {
			Authorization: "Bearer " + localStorage.getItem("token"),
			userId: `${getUserId()}`
		},
	});
	return response.json();
};

//::::::::::::::::::::::::://

export const getlocation = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/locations`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getMusic = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/musicFiles`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
	// return dummyMusicrowDatra;
};

export const getDownloadedReportData = async (data: any): Promise<any[]> => {
	const URL = `${API.REPORTS_URL}/download/list`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
		method: 'POST',
		body: JSON.stringify(data),
	});
	return response.json();
};

export const isFileDownloadExists = async ({ audioUrl = '', seqId = '' }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/isFileExist`;
	const data: any = {
		audioUrl: audioUrl,
		seqId: seqId,
	};
	const { status, response } = await makeApiCall1(URL, 'POST', data);
	return status ? response : response;
};

export const getMusicById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/musicFiles/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const deleteMusicById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/musicFiles/${id}`;
	const response = await fetch(URL, {
		method: "DELETE",
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const deleteReportsDownloadById = async ({ userId = '', seqId = '' }): Promise<any> => {
	const URL = `${API.REPORTS_URL}/delete/entry`;
	const data: any = {
		userId: userId,
		seqId: seqId,
	};
	const { status, response } = await makeApiCall1(URL, 'POST', data);
	return status ? response : response;
};

export const saveMusic = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/musicFiles`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const updateMusicById = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/musicFiles/${data?.id}`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getLocationById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/locations/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const addlocation = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/locations`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const updatelocationById = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/locations/${data.LocationID}`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const deletelocationById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/locations/${id}`;
	const response = await fetch(URL, {
		method: "DELETE",
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getskilllocationDropdown = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/locationList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getphoneNumberDropdown = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/phoneNumberList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};
export const getphoneNumberDropdownWithName = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/phoneNumberListWithName`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};
export const getskillsDropdown = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/skillList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};


export const getMappings = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/previewMapList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const deleteMappingById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/previewMaps/${id}`;
	const response = await fetch(URL, {
		method: "DELETE",
		headers: getAuthHeaders(),
	});
	return response.json();
};
export const getEmailById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/templates/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};
export const getWhatsappById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/templates/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};
export const getSmsById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/templates/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};
export const deleteTemplateById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/templates/${id}`;
	const response = await fetch(URL, {
		method: "DELETE",
		headers: getAuthHeaders(),
	});
	return response.json();
};
export const addTemplate = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/templates`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};
export const updateTemplate = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/templates/${data.id}`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};
export const getTemplates = async (type: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/templates?type=${type}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getDNCRegulation = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/dncRegulations`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const dncRegulationsDropDownList = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/timeZoneList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
}

export const addNewDNCRegulation = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/dncRegulations`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};


export const getSettingsList = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/settings`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getSettingsListByCode = async (params = { isAdmin: '', settingsCode: '' }): Promise<any> => {
	try {
		const URL = `${API.ADMIN_BASE_URL}/settingsByCode`;
		const data: any = { isAdmin: params.isAdmin, settingsCode: params.settingsCode };
		const response = await fetch(URL, { method: 'POST', body: JSON.stringify(data), headers: getAuthHeaders() });
		return response.json();
	} catch (err) {
		console.log('Settings error', err);
		return err;
	}
};

export const getAdminSettingsList = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/settingsByAdmin`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getContinuousBargeStatus = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/isContinuousBargeEnabled/${getUserId()}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getUserLoggedIn = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/getLoggedInUser`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const changePasswordUser = async (data: any, id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/users/${id}`;
	const response = await fetch(URL, {
		method: "PUT",
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const updatePasswordAdmin = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/userProfile`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};



export const settingsSave = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/settings`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getIvrDropdown = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/ivrFlowList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};
export const getSkillDropdown = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/skillList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getAvailableIntegration = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/getAvailableIntegrations`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getEnabledUserIntegrations = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/getEnabledUserIntegrations`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getintegrationStateResponse = async (name: any): Promise<any> => {
	const URL = `http://10.233.17.168:8590/auth/zoho/integration/encrypt?${name}`;
	const response = axios.get(URL);
	return response;
};

export const getCampiagnsByPluginType = async (name: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/getCampiagnsByPluginType/${name}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getPhoneNumberListWithName = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/phoneNumberListWithName`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getDidListByIntegration = async (name: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/getDidListByIntegration/${name}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getAgentIdMappings = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/agents`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};
export const getIvrFlowDesginer = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/ivrFlowDetails/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};
export const saveIvrFlowDesginer = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/ivrFlowDetails/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getUserModules = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/getUserModules`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getFeatureList = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/feature/list`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getRoleList = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/plan/role-list`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getAgentGroupsList = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/agentGroupsList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getUserCampaignList = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/usercampaignList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getProfileList = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/profile/list`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getPlans = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/plan/list`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getPlanById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/plan/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const addEditPlan = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/plan/save`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const deletePlanById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/plan/${id}`;
	const response = await fetch(URL, {
		method: "DELETE",
		headers: getAuthHeaders(),
	});
	return response.json();

};

export const getSuperAdminUserList = async (data: any): Promise<any> => {
	const URL = `${API.ADMIN_BASE_URL}/adminUsersList/`;
	const { status, response } = await makeAdminListApiCall(URL, 'GET', null, true, null, data.token, data.userId);
	return status ? response?.json() : response;
};

const makeAdminListApiCall = async (URL: string, method: HTTP_METHOD, data: any = null, withUserId: boolean = false, mockData: any = null, token: any = null, userId: any = null) => {
	try {
		const body = withUserId ? { ...data, UserId: getUserId() } : { ...data };
		const requestParams: any = { method, headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`, userId: `${userId}` } };
		if (data) {
			requestParams.body = JSON.stringify(body);
		}
		const response = await fetch(URL, { ...requestParams });
		return { status: true, response };
	} catch (err) {
		console.log(err);
		return { status: false, response: mockData ? mockData : err };
	}
};

export const deleteDNCRegulationById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/dncRegulations/${id}`;
	const response = await fetch(URL, {
		method: "DELETE",
		headers: getAuthHeaders(),
	});
	return response.json();

};

export const getDNCRegulationById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/dncRegulations/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};


export const updateDNCRegulationById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/dncRegulations/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
		method: "PUT",
	});
	return response.json();
};

export const getsipLocation = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/sipLocations`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getsipLocationById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/sipLocations/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const addsipLocation = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/sipLocations`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getDeleteCampaignData = async (data: any, id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/deleteCampaignData/${id}`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getResetCampaignData = async (data: any, id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/campaignResetData/${id}`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const updatesipLocationById = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/sipLocations/${data.id}`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const deletesipLocationById = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/sipLocations/${id}`;
	const response = await fetch(URL, {
		method: "DELETE",
		headers: getAuthHeaders(),
	});
	return response.json();
}
export const getCampaignResetData = async (data: any, id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/generateCampaignResetData/${id}`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getCampaignResetDataWithMappingColumns = async (data: any, id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/campaignPreviewData/${id}`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getDownloadCampaignList = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/campaignDownloadDataList`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const importPauseReasons = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/endpoint/data/bulk/pause/reason`;
	const response = await fetch(URL, {
		method: "POST",
		body: data,
		headers: {
			Authorization: "Bearer " + localStorage.getItem("token"),
			userId: `${getUserId()}`
		},
	});
	return response.json();
};

export const importDispositions = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/endpoint/data/bulk/disposition/upload`;
	const response = await fetch(URL, {
		method: "POST",
		body: data,
		headers: {
			Authorization: "Bearer " + localStorage.getItem("token"),
			userId: `${getUserId()}`
		},
	});
	return response.json();
};


export const importBulkFWPNumbers = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/endpoint/data/bulk/fwpNumbers/upload/save`;
	const response = await fetch(URL, {
		method: "POST",
		body: data,
		headers: {
			Authorization: "Bearer " + localStorage.getItem("token"),
			userId: `${getUserId()}`
		},
	});
	return response.json();
};

export const uploadEditBulkFWPNumbers = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/endpoint/data/bulk/fwpNumbers/upload/update`;
	const response = await fetch(URL, {
		method: "POST",
		body: data,
		headers: {
			Authorization: "Bearer " + localStorage.getItem("token"),
			userId: `${getUserId()}`
		},
	});
	return response.json();
};

export const importBulkAgents = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/endpoint/data/bulk/agent/upload`;
	const response = await fetch(URL, {
		method: "POST",
		body: data,
		headers: {
			Authorization: "Bearer " + localStorage.getItem("token"),
			userId: `${getUserId()}`
		},
	});
	return response.json();
};
export const resetOutboundCampaign = async (data: any, id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/generateCampaignResetData/${id}`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};
export const getReasonsDropdown = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/campaignReasonList/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};
export const getCampaignDispositonsDropdown = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/campaignDispositionList/${id}`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getNewCampaignReasonsDropdown = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/campaignData/campaignReasonList/`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
		method: "POST",
		body: JSON.stringify(data),
	});
	return response.json();
};

export const getNewCampaignDispositonsDropdown = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/campaignData/campaignDispositionList/`;
	const response = await fetch(URL, {
		headers: getAuthHeaders(),
		method: "POST",
		body: JSON.stringify(data),
	});
	return response.json();
};

export const downloadReport = async (id: any, data: any): Promise<any> => {
	// const URL = `${API.REPORTS_URL}/downloadFile/${id}`;
	const URL = `${API.REPORTS_URL}/async/downloadFile/${id}`;
	let response = await axios
		.post(URL, data, {
			headers: getAuthHeaders(),
			// responseType: "blob",
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err;
		});
	return response;
};
export const getReportsFields = async (id: any): Promise<any> => {
	const URL = `${API.REPORTS_URL}/inputControls/${id}`;
	const { status, response } = await makeApiCall(URL, "GET");
	return status ? response?.json() : response;
};
export const getReportsListDropdownInSchduler = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_REPORT_URL}/userJasperReportTemplates`;
	let response = await axios
		.get(URL, {
			headers: getAuthHeaders(),
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err;
		});
	return response;
};
export const getReportsListDropdown = async (): Promise<any[]> => {
	const URL = `${API.REPORTS_URL}/jaspersoft/report/list`;
	let response = await axios
		.get(URL, {
			headers: getAuthHeaders(),
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err;
		});
	return response;
};
export const getUsersListDropdown = async (): Promise<any[]> => {
	const URL = `${API.REPORT_BASE_URL}/admin/adminUserList/-2`;
	let response = await axios
		.get(URL, {
			headers: getAuthHeaders(),
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err;
		});
	return response;
};
export const addReport = async (data: any): Promise<any> => {
	const URL = `${API.REPORTS_URL}/saveJasperReportTemplate`;
	let response = await axios
		.post(URL, data, {
			headers: getAuthHeaders(),
			responseType: "json",
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err;
		});
	return response;
};

export const addUserReport = async (data: any): Promise<any> => {
	const URL = `${API.ADMIN_REPORT_URL}/saveUserJasperReportTemplate`;
	let response = await axios
		.post(URL, data, {
			headers: getAuthHeaders(),
			responseType: "json",
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err;
		});
	return response;
};
export const getFeildsData = async (id: any): Promise<any> => {
	const URL = `${API.REPORTS_URL}/CustomReportTemplateInfo/${id}`;
	let response = await axios
		.get(URL, {
			headers: getAuthHeaders(),
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err;
		});
	return response;
};
export const saveReport = async (data: any): Promise<any> => {
	const URL = `${API.REPORTS_URL}/CustomReportTemplateUpdate`;
	let response = await axios
		.post(URL, data, {
			headers: getAuthHeaders(),
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err;
		});
	return response;
};
/* export const deleteReport = async (formData: FormData): Promise<any> => {
  const URL = `${API.REPORTS_URL}/CustomReportTemplateDelete`;
  let response = await axios
	.post(URL, formData, {
	  headers: getAuthHeaders(),
	})
	.then((response) => {
	  return response;
	})
	.catch((err) => {
	  return err;
	});
  return response;
}; */

/* export const deleteReport = async (formData: any) => {
  const URL = `${API.REPORTS_URL}/CustomReportTemplateDelete`;
  axios.post(URL, formData).then((res) => {
	if (res) {
	  console.log(res);
	}
  });
}; */

export const deleteReport = async (formData: any): Promise<any> => {
	let userid = localStorage.getItem("userId");
	const URL = `${API.REPORTS_URL}/CustomReportTemplateDelete`;
	const response = await fetch(URL, {
		method: "POST",
		body: formData,
		headers: {
			Authorization: "Bearer " + localStorage.getItem("token"),
			userid: String(userid)
		},
	});
	return response.json();
};

export const schedulerReportsLists = async (params: any): Promise<any> => {
	const URL = `${API.REPORT_BASE_URL}/scheduler/jasperSchedulerList`;
	const { status, response } = await makeApiCall(URL, 'POST', params);
	return status ? response?.json() : response;
};
export const getCDR = async (): Promise<any> => {
	const URL = `${API.FILTERS_URL}/CDR`;
	const { status, response } = await makeApiCall(URL, "GET");
	return status ? response?.json() : response;
};

export const schedulerReportsAdd = async (reportId: any, data: any): Promise<any> => {
	const URL = `${API.REPORT_BASE_URL}/scheduler/customParamsCheck/${reportId}`;
	const response = await axios.post(URL, data, { headers: getAuthHeaders(), })
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err;
		});
	return response;

};

export const schedulerJobDetails = async (id: any, data: any): Promise<any> => {
	let userid = localStorage.getItem("userId");
	const URL = `${API.REPORTS_URL}/getJobdetails/${id}`;
	const response = await fetch(URL, {
		method: "POST",
		body: data,
		headers: {
			Authorization: "Bearer " + localStorage.getItem("token"),
			userid: String(userid)
		},
	});
	return response.json();
};

export const deleteScheduler = async (id: any): Promise<any[]> => {
	let userid = localStorage.getItem("userId");
	const URL = `${API.SCHEDULER_URL}/deleteJasperSoftJob/${id}`;
	const response = await fetch(URL, {
		method: "POST",
		headers: {
			Authorization: "Bearer " + localStorage.getItem("token"),
			userid: String(userid)
		},
	});
	return response.json();
};

export const getEditFormResponse = async (id: any): Promise<any[]> => {
	let userid = localStorage.getItem("userId");
	const URL = `${API.SCHEDULER_URL}/getJasperSchedulerID/${id}`;
	const response = await fetch(URL, {
		method: "POST",
		headers: {
			Authorization: "Bearer " + localStorage.getItem("token"),
			userid: String(userid)
		},
	});
	return response.json();
};
export const getEditDynamicFeildsData = async (id: any): Promise<any[]> => {
	let userid = localStorage.getItem("userId");
	const URL = `${API.REPORTS_URL}/scheduledReportParamters/${id}`;
	const response = await fetch(URL, {
		method: "GET",
		headers: {
			Authorization: "Bearer " + localStorage.getItem("token"),
			userid: String(userid)
		},
	});
	return response.json();
};
export const schedulerReportEdit = async (reportId: any, data: any): Promise<any> => {
	const URL = `${API.SCHEDULER_URL}/updatecustomParamsCheck/${reportId}`;
	const response = await axios.post(URL, data, { headers: getAuthHeaders(), })
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err;
		});
	return response;

};

export const getSkillSummaryDigital = async ({ type = 1, filters = '0', callType = 'All' }): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 133,
		Type: type,
		FilterId: filters,
		DigitalType: callType
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};

export const getChanelStatusData = async (params = { filters: '0', type: '1', callType: 'All', dateTypeId: '0' }) => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 132,
		Type: params.type,
		FilterId: params.filters,
		DigitalType: params.callType,
		DateTypeId: params.dateTypeId,
		Flag: 1
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};
// const { status, response } = await makeApiCall(URL, 'POST', data, true, CALL_STATUS_SINGLE_MOCK);

// const mockRes = [
//     {
//         "DigitalType" : "whatapp",
//         "TotalConversations" : 1255,
//         "ConnectedConversations" : 255,
//         "AbandonedConversations" : 1000,
//         "TotalSessionTime" : 1323,
//         "AvgSessionTime" : 15453,
//         "AvgFirstResponseTime" : 898,
//         "AvgResponseTime" : 455
//     },
//     {
//         "DigitalType" : "webchat",
//         "TotalConversations" : 1905,
//         "ConnectedConversations" : 905,
//         "AbandonedConversations" : 1000,
//         "TotalSessionTime" : 1434,
//         "AvgSessionTime" : 1666,
//         "AvgFirstResponseTime" : 564,
//         "AvgResponseTime" : 765
//     },
//     {
//         "DigitalType" : "SMS",
//         "TotalConversations" : 1905,
//         "ConnectedConversations" : 1005,
//         "AbandonedConversations" : 900,
//         "TotalSessionTime" : 6434,
//         "AvgSessionTime" : 1966,
//         "AvgFirstResponseTime" : 964,
//         "AvgResponseTime" : 1765
//     },
//     {
//         "DigitalType" : "Email",
//         "TotalConversations" : 1224,
//         "ConnectedConversations" : 705,
//         "AbandonedConversations" : 1911,
//         "TotalSessionTime" : 1534,
//         "AvgSessionTime" : 1996,
//         "AvgFirstResponseTime" : 504,
//         "AvgResponseTime" : 1909
//     }
// ];
// console.log(mockRes);
// return mockRes;
// };
export const getAgentPerformanceDigital = async (params = { type: 3, filters: '0' }): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 134,
		Type: params.type,
		FilterId: params.filters,
		DigitalType: 'All'
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};


export const getWidgetDetails = async (data: any, id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/dashboardReportUpdate`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify({ 'action': 'list', 'type': 'widget' }),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getReportDetails = async (data: any, id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/dashboardReportUpdate`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify({ 'action': 'list', type: 'report' }),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getActivityLogDetails = async (): Promise<any[]> => {
	const URL = `${API.BASE_URL}/monitor/api`;
	const data: any = {
		apiId: 146,
		Type: checkDataAccessFilters() ? 3 : 1,
		FilterId: "0"
	};
	const { status, response } = await makeApiCall(URL, 'POST', data, true);
	return status ? response?.json() : response;
};


export const getDashboardWidgetById = async (apiID: any, type: any, action: any) => {
	const URL = `${API.ADMIN_BASE_URL}/dashboardReportUpdate`;
	const data = {
		apiID: apiID,
		type: type,
		action: action,
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};


export const deleteWidgetById = async (apiID: any, action: any, type: any) => {
	const URL = `${API.ADMIN_BASE_URL}/dashboardReportUpdate`;
	const data = {
		apiID: apiID,
		action: action,
		type: type,
	};
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};



export const addReportForm = async (datas: any) => {
	const URL = `${API.ADMIN_BASE_URL}/dashboardReportUpdate`;
	const data = datas
	const { status, response } = await makeApiCall(URL, 'POST', data);
	return status ? response?.json() : response;
};

export const whatsAppIntegrationviaFacebook = async (url: any): Promise<any[]> => {
	const response = await fetch(url, {
		method: 'GET',
		headers: {
			'Content-Type': 'text/plain',
		},
		mode: 'same-origin', // You can change this based on your CORS requirements
		credentials: 'same-origin', // You can change this based on your CORS requirements
		cache: 'no-cache',
		redirect: 'follow',
	})
	return response.json()
};

export const whatsAppDIDURL = async (url: any, data: any): Promise<any[]> => {
	const response = await fetch(url, {
		method: 'POST',
		body: data,
	})
	return response.json()
}

export const saveIdeskIntegrationData = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/saveIdeskIntegrationData`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const enableIdeskIntegration = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/saveIdeskIntegration`;
	const response = await fetch(URL, {
		method: "POST",
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const removeIdeskIntegration = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/removeIdeskIntegration`;
	const response = await fetch(URL, {
		method: "GET",
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getLoggedInAgentsGroupWise = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/getLoggedInAgentsGroupWise`;
	const response = await fetch(URL, {
		method: "GET",
		headers: getAuthHeaders(),
	});
	return response.json();
}

export const fetchBroadCastMessage = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/fetchBroadCastMessage`;
	const response = await fetch(URL, {
		method: "GET",
		headers: getAuthHeaders(),
	});
	return response.json();
}

export const getAllAgentsGroupWise = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/getAgentsGroupWise`;
	const response = await fetch(URL, {
		method: "GET",
		headers: getAuthHeaders(),
	});
	return response.json();
}

export const sendBroadCastMessage = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/sendBroadCastMessage`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
}

export const getZohoMappings = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/getZohoAgentIdMappings`;
	const response = await fetch(URL, {
		method: "GET",
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const saveZohoAgentMapping = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/saveZohoUserMapping`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const saveZohoFwpNumberMapping = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/saveZohoFwpMapping`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const saveZohoDidMapping = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/saveZohoDidMapping`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const revokeIntegration = async (integrationName: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/revokeIntegrations?name=${integrationName}`;
	const response = await fetch(URL, {
		method: "GET",
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const enableIntegration = async (data: any, integrationName: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/saveUserIntegration/${integrationName}`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const removeIntegrations = async (name: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/removeIntegration/${name}`;
	const response = await fetch(URL, {
		method: "GET",
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const saveIntegrations = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/saveIntegration`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const editIntegrations = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/editIntegration`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const enableClickToCall = async (id: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/enableClickToCallToCampaign/${id}`;
	const response = await fetch(URL, {
		method: "GET",
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const digitalCampaignsDetails = async (campaignId: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/getDigitalCampaignIntegrations/${campaignId}`;
	const response = await fetch(URL, {
		method: "GET",
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const editDigitalCampaigns = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/editCampaignIntegration`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const saveDigitalCampaigns = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/saveCampaignIntegration`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const removeDigitalCampaignsIntegrations = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/removeCampaignIntegration`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const saveSSOLoginWithLDAP = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/saveSsoDetails`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

const getAuthHeadersWithoutContentType = () => {
	// return { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthToken()}` };
	return { Authorization: `Bearer ${getAuthToken()}`, userId: `${getUserId()}`, dAccessType: 'false' };
};

export const saveSSOLoginWithSAML = async (data: FormData): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/saveSsoDetails`;
	const response = await fetch(URL, {
		method: "POST",
		body: data,
		headers: getAuthHeadersWithoutContentType(),
	});
	return response.json();
};

export const saveCloudStorage = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/saveCloudStorageDetails`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const removeSsoLoginORCloudStorage = async (name: any, isActive: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/${name}?isActive=${isActive}`
	const response = await fetch(URL, {
		method: "DELETE",
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const bulkUpdateSettings = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/bulkCallControlsUpdate`;
	const response = await fetch(URL, {
		method: "POST",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getHubspotMappings = async (): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/getHubspotAgentIdMappings`;
	const response = await fetch(URL, {
		method: "GET",
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const saveHubspotAgentMapping = async (data: any): Promise<any[]> => {
	const URL = `${API.ADMIN_BASE_URL}/saveHubspotUserMapping`;
	const response = await fetch(URL, {
		method: "PUT",
		body: JSON.stringify(data),
		headers: getAuthHeaders(),
	});
	return response.json();
};

export const getSecuredRecordingUrl = async (data: any): Promise<any> => {
	const URL = `${API.REPORTS_URL}/secureRecordingUrl`;
	let response = await axios
		.post(URL, data, {
			headers: getAuthHeaders(),
			responseType: "json",
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err;
		});
	return response;
};