import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import WidgetGraph from '../../Widget-Graph/Widget-Graph';
import WidgetSearch from '../../Widget-Search/Widget-Search';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Popover, Stack, Theme, Typography, Badge } from '@mui/material';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { getAgentEvents, getAgentList, getAgentStateDetail, getAdminSettingsList, logoutAgent, releaseAgent, getContinuousBargeStatus, getWidgetConfig, getAgentDetailById } from '../../../services/api-service';
import '../AgentCurrentState/AgentCurrentState.css';
import BargeInDrawer from '../../BargeInDrawer/BargeInDrawer';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactComponent as DragIcon } from '../../../static/icons/drag.svg';
import SelectPopover from '../../SelectPopover/SelectPopover';
import { STATUS_CLASS_MAP, WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';
import { AccessTime } from '@mui/icons-material';
import ThresholdDrawer from '../../ThresholdDrawer/ThresholdDrawer';
import { checkRoles, mapRowDataWithThreshold, secondsToMinSec, secondsToTime, checkDataAccessFilters, determineFilterType } from '../../../utils/util';
import BargeInDrawerSip from '../../BargeinDrawerSip/BargeinDrawerSip';
import { Userpilot } from 'userpilot';
import VirtualTable from './VirtualTable';
import VideoPlayerIcon from '../../admin/common/VideoPlayer/VideoPlayer';
import { ReactComponent as ScreenBargeinIcon } from '../../../static/icons/screenBargein.svg';


interface Column {
	id:
	| 'AgentName'
	| 'agentAction'
	| 'HoldDuration'
	| 'Since'
	| 'AgentState'
	| 'CallStatus'
	| 'adminAction'
	| 'hamburger'
	| 'CampaignNameOfLastCall'
	| 'CallFrom_SkillName'
	| 'CallType'
	| 'CustomerPhoneNumber'
	| 'AgentMode'
	| 'AgentId'
	| 'SkillsFormatted'
	| 'GroupsFormatted'
	| 'AgentPhoneNumber'
	| 'sip_location'
	label: string;
	minWidth?: number;
	draggable?: boolean;
	align?: 'right' | 'left' | 'center';
	positionIndex?: number;
	type?: string;
}
const useStyles: any = makeStyles((theme: Theme) => ({
	popoverContent: {
		minWidth: 350,
		fontSize: '12px',
		lineHeight: 2
	},
	dragdropItemsWrapper: {
		display: 'flex',
		height: '100%',
		width: '100%',
		margin: '0 1rem',
		alignItems: 'center'
	}
}));

const defaultColumns: Column[] = [
	{ id: 'AgentId', label: 'Agent Id', minWidth: 120 },
	{ id: 'AgentName', label: 'Agent Name', minWidth: 120 },
	{ id: 'agentAction', label: '', minWidth: 30 },
	{
		id: 'CampaignNameOfLastCall',
		label: 'Campaign Name',
		draggable: true,
	},
	{
		id: 'CallFrom_SkillName',
		label: 'Call From Skill',
		draggable: true,
	},
	{
		id: 'CallType',
		label: 'Call Type',
		draggable: true,
		minWidth: 50,
	},
	{ id: 'AgentPhoneNumber', label: 'Agent Phone No.', minWidth: 180 },
	{ id: 'sip_location', label: 'SIP Location', minWidth: 180 },
	{ id: 'SkillsFormatted', label: 'Skills', minWidth: 50, align: 'left' },
	// { id: 'GroupsFormatted', label: 'Groups', minWidth: 50, align: 'left' },
	{ id: 'CustomerPhoneNumber', label: 'Customer No.', minWidth: 180 },
	{
		id: 'AgentMode',
		label: 'Mode',
		draggable: true,
		minWidth: 30,
	},
	{ id: 'HoldDuration', label: 'Hold Time', minWidth: 150, type: 'TIME' },
	{ id: 'AgentState', label: 'State', draggable: true, minWidth: 50 },
	{ id: 'CallStatus', label: 'Last Call', minWidth: 180, align: 'left' },
	{ id: 'Since', label: 'Duration', minWidth: 50, type: 'TIME' },
	{
		id: 'adminAction',
		label: 'Admin Action',
		minWidth: checkRoles("ROLE_SCREEN_RECORDING") ? 260 : 185,
	},
	{ id: 'hamburger', label: '', minWidth: 20 }
];

const columnsWithDataAccess: Column[] = [
	{ id: 'AgentId', label: 'Agent Id', minWidth: 120 },
	{ id: 'AgentName', label: 'Agent Name', minWidth: 120 },
	{ id: 'agentAction', label: '', minWidth: 30 },
	{
		id: 'CampaignNameOfLastCall',
		label: 'Campaign Name',
		draggable: true,
	},
	{
		id: 'CallFrom_SkillName',
		label: 'Call From Skill',
		draggable: true,
	},
	{ id: 'GroupsFormatted', label: 'Groups', minWidth: 50 },
	{
		id: 'CallType',
		label: 'Call Type',
		draggable: true,
		minWidth: 50,
	},
	{ id: 'AgentPhoneNumber', label: 'Agent Phone No.', minWidth: 180 },
	{ id: 'sip_location', label: 'SIP Location', minWidth: 180 },
	{ id: 'SkillsFormatted', label: 'Skills', minWidth: 50, align: 'left' },
	// { id: 'GroupsFormatted', label: 'Groups', minWidth: 50, align: 'left' },
	{ id: 'CustomerPhoneNumber', label: 'Customer No.', minWidth: 180 },
	{
		id: 'AgentMode',
		label: 'Mode',
		draggable: true,
		minWidth: 30,
	},
	{ id: 'HoldDuration', label: 'Hold Time', minWidth: 150, type: 'TIME' },
	{ id: 'AgentState', label: 'State', draggable: true, minWidth: 50 },
	{ id: 'CallStatus', label: 'Last Call', minWidth: 180, align: 'left' },
	{ id: 'Since', label: 'Duration', minWidth: 50, type: 'TIME' },
	{
		id: 'adminAction',
		label: 'Admin Action',
		minWidth: checkRoles("ROLE_SCREEN_RECORDING") ? 260 : 185,
	},
	{ id: 'hamburger', label: '', minWidth: 20 }
];

const defaultDownloadColumns: Column[] = [
	{ id: 'AgentId', label: 'Agent Id', minWidth: 120 },
	{ id: 'AgentName', label: 'Agent Name', minWidth: 80 },
	{ id: 'CampaignNameOfLastCall', label: 'Campaign Name', draggable: true },
	{ id: 'CallFrom_SkillName', label: 'Call From Skill', draggable: true },
	{ id: 'CallType', label: 'Call Type', draggable: true, minWidth: 50 },
	{ id: 'AgentPhoneNumber', label: 'Agent Phone No.', minWidth: 50 },
	{ id: 'sip_location', label: 'SIP Location', minWidth: 50 },
	{ id: 'SkillsFormatted', label: 'Skills', minWidth: 50 },
	// { id: 'GroupsFormatted', label: 'Groups', minWidth: 50 },
	{ id: 'CustomerPhoneNumber', label: 'Customer No.', minWidth: 50 },
	{ id: 'AgentMode', label: 'Mode', draggable: true, minWidth: 30 },
	{ id: 'HoldDuration', label: 'Hold Time', minWidth: 65, type: 'TIME' },
	{ id: 'AgentState', label: 'State', draggable: true, minWidth: 50 },
	{ id: 'CallStatus', label: 'Last Call Status', minWidth: 50 },
	{ id: 'Since', label: 'Duration', minWidth: 50, type: 'TIME' },
];

const downloadColumnsWithDataAccess: Column[] = [
	{ id: 'AgentId', label: 'Agent Id', minWidth: 120 },
	{ id: 'AgentName', label: 'Agent Name', minWidth: 80 },
	{ id: 'CampaignNameOfLastCall', label: 'Campaign Name', draggable: true },
	{ id: 'CallFrom_SkillName', label: 'Call From Skill', draggable: true },
	{ id: 'GroupsFormatted', label: 'Groups', minWidth: 50 },
	{ id: 'CallType', label: 'Call Type', draggable: true, minWidth: 50 },
	{ id: 'AgentPhoneNumber', label: 'Agent Phone No.', minWidth: 50 },
	{ id: 'sip_location', label: 'SIP Location', minWidth: 50 },
	{ id: 'SkillsFormatted', label: 'Skills', minWidth: 50 },
	// { id: 'GroupsFormatted', label: 'Groups', minWidth: 50 },
	{ id: 'CustomerPhoneNumber', label: 'Customer No.', minWidth: 50 },
	{ id: 'AgentMode', label: 'Mode', draggable: true, minWidth: 30 },
	{ id: 'HoldDuration', label: 'Hold Time', minWidth: 65, type: 'TIME' },
	{ id: 'AgentState', label: 'State', draggable: true, minWidth: 50 },
	{ id: 'CallStatus', label: 'Last Call Status', minWidth: 50 },
	{ id: 'Since', label: 'Duration', minWidth: 50, type: 'TIME' },
];

const thresholdItemsDefault = [
	// {
	// 	label: 'Idle',
	// 	key: 'AgentState',
	// 	value: 'IDLE',
	// 	fields: [{ label: `Duration(in sec's)`, key: 'Since', value: '', condition: 'GREATER_THAN' }]
	// },
	{
		label: 'Ready',
		key: 'AgentState',
		value: 'READY' || 'Ready',
		fields: [{ label: `Duration(in sec's)`, key: 'Since', value: '', condition: 'GREATER_THAN' }]
	},
	{
		label: 'Busy',
		key: 'AgentState',
		value: 'BUSY' || 'Busy',
		fields: [
			{ label: `Duration(in sec's)`, key: 'Since', value: '', condition: 'GREATER_THAN' },
			{ label: `Hold Time(in sec's)`, key: 'HoldDuration', value: '', condition: 'GREATER_THAN' }
		]
	},
	{
		label: 'ACW',
		key: 'AgentState',
		value: 'ACW' || 'Acw',
		fields: [{ label: `Duration(in sec's)`, key: 'Since', value: '', condition: 'GREATER_THAN' }]
	},
	{
		label: 'Pause',
		key: 'AgentState',
		value: 'PAUSED' || 'Paused',
		fields: [{ label: `Duration(in sec's)`, key: 'Since', value: '', condition: 'GREATER_THAN' }]
	},
	// {
	// 	label: 'Calling',
	// 	key: 'AgentState',
	// 	value: 'CALLING' || 'Calling',
	// 	fields: [{ label: `Duration(in sec's)`, key: 'Since', value: '', condition: 'GREATER_THAN' }]
	// },
	{
		label: 'AUX',
		key: 'AgentState',
		value: 'AUX' || 'Aux',
		fields: [{ label: `Duration(in sec's)`, key: 'Since', value: '', condition: 'GREATER_THAN' }]
	}
];

let interval: any;

export default function AgentCurrentState(props: any) {

	const { useState, useRef } = React;
	const classes = useStyles();
	const [responseLength, setresponseLength] = useState<Number>(0);
	const [allowedFilters, setAllowedFilters] = useState<string[]>(['Campaign', 'Skill', 'Group']);
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [rowData, setRowData] = useState<Array<any>>([]);
	const [columns, setColumns] = useState<Array<any>>(defaultColumns);
	const [downloadColumns, setDownloadColumns] = useState<Array<any>>(defaultDownloadColumns);
	const [filteredRowData, setFilteredRowData] = useState<Array<any>>([]);
	const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
	const [isEventsPopoverOpen, setIsEventsPopoverOpen] = useState<boolean>(false);
	const [isColumnsPopoverOpen, setIsColumnsPopoverOpen] = useState<boolean>(false);
	const [isBargeInOpen, setIsBargeInOpen] = useState<boolean>(false);
	const [eleRefPoint, setElRefPoint] = useState<any>(null);
	const [columnsEleRefPoint, setColumnsElRefPoint] = useState<any>(null);
	const [selectedAgent, setSelectedAgent] = useState<any>(null);
	const [selectedAgentDetails, setSelectedAgentDetails] = useState<any>(null);
	const [selectedAgentEventDetails, setSelectedAgentEventDetails] = useState<any>(null);
	const [selectedColumns, setSelectedColumns] = useState<any>(props?.selectedFilters?.filterHeaders || columns);
	const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || checkDataAccessFilters() ? 3 : 1);
	const [filterSection, setFilterSection] = useState<any>(props?.selectedFilters?.filterSection || {});
	const [agentListFilter, setAgentListFilter] = useState<any>(props?.selectedFilters?.filters || []);
	const downloadContent = useRef<null | any>(null);
	const [groupingColumns, setGroupingColumns] = useState<any>([]);
	const [groupedData, setGroupedData] = useState<any>({});
	const [isThresholdDrawerOpen, setIsThresholdDrawerOpen] = useState<boolean | undefined>(false);
	const [thresholdItems, setThresholdItems] = useState<Array<any>>(thresholdItemsDefault);
	const [openAlert, setOpenAlert] = React.useState(false);
	const [openLogOffAlert, setOpenLogOffAlert] = React.useState(false);
	const [releaseDetails, setReleaseDetails] = React.useState<any>([]);
	const [logOffDetails, setLogOffDetails] = React.useState<any>([]);
	const [searchText, setSearchText] = useState<any>({});
	const [sipClient, setSipClient] = useState<any>("");
	const [sipUrl, setSipUrl] = useState<any>("");
	const [bargeinCallType, setBargeinCallType] = useState<any>("");
	const [onselect, setOnSelect] = useState(false);
	const [settings, setSettings] = useState<any>([]);
	const [continuousBarge, setContinuousBarge] = useState(false);
	const [Data, setData] = useState<Array<any>>([]);
	const tableContainerRef = useRef(null);
	const tableDataRef = useRef(null);
	const [resized, setResized] = useState<any>(false);
	const [moreData, setMoreData] = useState<any>(false);
	const [selectedDownloadData, setSelectedDownloadData] = useState<any>(downloadColumns);
	const [screenBargeinData, setScreenBargeinData] = useState<any>(null);

	React.useEffect(()=>{
		selectedDownloadColumns();
	},[selectedColumns])

	React.useEffect(() => {
		if(checkRoles("ROLE_DATA_ACCESS")){
			setColumns(columnsWithDataAccess);
			setDownloadColumns(downloadColumnsWithDataAccess);
			setSelectedColumns(columnsWithDataAccess);
			setSelectedDownloadData(downloadColumnsWithDataAccess);
		  }else{
			setColumns(defaultColumns);
			setDownloadColumns(defaultDownloadColumns);
			setSelectedColumns(defaultColumns);
			setSelectedDownloadData(defaultDownloadColumns);
		  }

	}, []);

	React.useEffect(()	=> {
		const fetchWidgetConfig = async () => {
			try {
				if(checkDataAccessFilters()) {
					let widgetConfig = await getWidgetConfig('104', 'widget');
					if (widgetConfig) {
						if (typeof widgetConfig === 'string') {
							widgetConfig = JSON.parse(widgetConfig);
						}
						// Check the condition and set the appropriate filters
						const filters = widgetConfig.data && checkDataAccessFilters()
										? widgetConfig.data
										: widgetConfig.config;
		
						if (filters) {
							const filtersArray = filters.split(',');
							setAllowedFilters(filtersArray);
							const determinedFilterType = determineFilterType(filtersArray);
							if (determinedFilterType !== undefined) {
								setFilterType(determinedFilterType);
							}
						}
					}
				}
			} catch (error) {
				console.error('Error fetching widget config:', error);
			}
		};
	fetchWidgetConfig();
}, []);

	React.useEffect(() => {
		Userpilot.initialize('456987');
		Userpilot.identify('456987',
			{
				userid: localStorage.getItem("userId"),
				username: localStorage.getItem("userName")
			}
		);
		Userpilot.track('AgentsList-Active', { name: localStorage.getItem("userName") });
		const resizeObserver = new ResizeObserver((entries) => {
			if (entries && entries.length > 0) {
				const newHeight: any = entries[0].target.clientHeight;
				if (parseInt(newHeight) > 488) {
					setResized(true)
				}
				if (parseInt(newHeight) === 408) {
					setResized(false)
				}
				if (parseInt(newHeight) === 618) {
					setResized(false)
				}
				else if (parseInt(newHeight) >= 748) {
				}
			}
		});

		const resizeDataObserver = new ResizeObserver((entries) => {
			if (entries && entries.length > 0) {
				const Height: any = entries[0].target.clientHeight;
				if (parseInt(Height) > 280) {
					setMoreData(true)
				}
			}
		});

		if (tableContainerRef.current) {
			resizeObserver.observe(tableContainerRef.current);
		}

		if (tableDataRef.current) {
			resizeDataObserver.observe(tableDataRef.current);
		}

		return () => {
			resizeObserver.disconnect();
			resizeDataObserver.disconnect()
		};
	}, []);

	const selectedDownloadColumns: any = ()=>{
		const selectedHeaders: Column[] = selectedColumns;
		if(props?.selectedFilters?.filterHeaders){
			const filterData = downloadColumns.filter(item1 => 
				selectedHeaders.some(item2 => item1.id === item2.id)
			);
			setSelectedDownloadData(filterData);
		}
	}

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: '#F0F1F2',
			color: '#000000',
			padding: '10px 16px'
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14,
			padding: '10px 16px'
		}
	}));
	const agentDetailsItems = [
		{ label: 'Total Login', key: 'TotalLoginDuration', type: true },
		{ label: 'Last Logout', key: 'LastLogoutTime' },
		{ label: 'Calls Offered', key: 'TotalCalls' },
		{ label: 'Answered', key: 'AnsweredCalls' },
		{ label: 'Abandoned', key: 'AbandonedCalls' },
		{ label: 'Average Hold Time', key: 'AverageHoldDuration' },
		{ label: 'AHT', key: 'AverageHandlingDuration' },
		{ label: 'ASA', key: 'AverageSpeedToAnswer' },
		{ label: 'ACW', key: 'TotalWrapupDuration' },
		{ label: 'Pause Time', key: 'TotalPauseDuration' }
	];

	React.useEffect(() => {
		getSettings();
		continuousBargeEnabled();
	}, [bargeinCallType])

	React.useEffect(() => {
		console.log("ROLE_CONTINUE_BARGE , continuousBarge",checkRoles("ROLE_CONTINUE_BARGE"),continuousBarge)
		if(selectedAgent && checkRoles("ROLE_CONTINUE_BARGE") && continuousBarge){
			const updatedData = rowData?.filter((item: any) => item?.AgentUniqueId == selectedAgent?.AgentUniqueId)
			if(updatedData.length > 0 && updatedData[0]?.AgentState != selectedAgent?.AgentState){
				if(updatedData[0]?.AgentState === 'BUSY'){
					updatedData[0].continuousBarge = true;
				}
				setSelectedAgent(updatedData[0]);
			}
		}
	}, [rowData])

	React.useEffect(() => {
		// Only set the interval if isBargeInOpen is true
		if (isBargeInOpen) {
			const intervalId = setInterval(async () => {
				if (selectedAgent && checkRoles("ROLE_CONTINUE_BARGE") && continuousBarge) {
					try {
						console.log("Trtch****************");
						let agentDetail = await fetchAgentDetail();
						if (agentDetail) {
							if (typeof agentDetail === 'string') {
								agentDetail = JSON.parse(agentDetail);
							}
							if(agentDetail[0]?.AgentState === 'BUSY') {
								agentDetail[0].continuousBarge = true;
							}
							setSelectedAgent(agentDetail[0]);
						}
					} catch (error) {
						console.log("Catch****************");
						console.error("Failed to fetch agent details", error);
					}
				}
			}, 2000); // 2 seconds interval
	
			// Cleanup interval if isBargeInOpen is false or component unmounts
			return () => clearInterval(intervalId);
		}
	}, [selectedAgent, continuousBarge, isBargeInOpen]);

	// Function to fetch agent details by ID
		const fetchAgentDetail = async () => {
			try {
				console.log("what is the selectedAgent--->",selectedAgent);
				let agentDetail = await getAgentDetailById(selectedAgent?.AgentUniqueId);
				if (agentDetail && typeof agentDetail === 'string') {
					agentDetail = JSON.parse(agentDetail);
				}
				return agentDetail;
			} catch (error) {
				console.error("Failed to fetch agent details", error);
				return null;
			}
		};

	const getPopoverContent = () => {
		return (
			<Box sx={{ p: 2 }} className={classes.popoverContent}>
				<div>
					{agentDetailsItems.map((item: any, idx: number) => (
						<Grid key={idx} container direction="row" justifyContent="space-between" alignItems="center">
							<Grid item xs={7}>
								<b>{item.label}</b>
							</Grid>
							{item.type ? <span>{secondsToTime(selectedAgentDetails?.[item.key]) || 0}</span> : <span>{selectedAgentDetails?.[item.key] || 0}</span>}
						</Grid>
					))}
				</div>
			</Box>
		);
	};

	const formatAMPM = (date: Date) => {
		if (date) {
			var hours = date.getHours();
			var minutes: any = date.getMinutes();
			var ampm = hours >= 12 ? 'PM' : 'AM';
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
			minutes = minutes < 10 ? '0' + minutes : minutes;
			var strTime = hours + ':' + minutes + ' ' + ampm;
			return strTime;
		} else {
			return '';
		}
	};

	const getColorByAgentEvent = (event: string) => {
		return event.toLowerCase() === 'calling'
			? 'yellow'
			: event.toLowerCase() === 'aux'
				? 'green'
				: event.toLowerCase() === 'release'
					? 'red'
					: event.toLowerCase() === 'idle'
						? 'orange'
						: event.toLowerCase() === 'incall'
							? 'lightgreen'
							: '';
	};

	const getTimeDifferenceInString = (higherDate: string, lowerDate: string) => {
		const seconds = (new Date(higherDate).getTime() - new Date(lowerDate).getTime()) / 1000;
		if (!isNaN(seconds)) {
			if (seconds > 60) {
				const minutes = seconds / 60;
				if (minutes > 60) {
					const hours = minutes / 60;
					return `${hours.toFixed(1)} Hrs`;
				} else {
					return `${Math.floor(minutes)} Mins`;
				}
			} else {
				return `${seconds} Secs`;
			}
		} else {
			return '';
		}
	};

	const getEventsPopoverContent = () => {
		return (
			<Box sx={{ p: 2 }} className={classes.popoverContent}>
				<div>
					<h3>{selectedAgent?.AgentName}</h3>
					<Stack direction={'column'} gap={1}>
						{selectedAgentEventDetails?.map((item: any, idx: number) => (
							<Box key={idx} display={'flex'} justifyContent="space-between" alignItems="center">
								<Box display={'flex'} gap={1}>
									{
										<Box
											sx={{
												background: getColorByAgentEvent(item.Event),
												width: '2px',
												height: '2rem',
												borderRadius: '4px'
											}}
										></Box>
									}
									<Box sx={{ display: 'flex', flexDirection: 'column' }}>
										<Stack direction={'row'} gap={5}>
											<Typography
												sx={{
													minWidth: '5rem',
													fontWeight: '500',
													fontSize: '12px',
													color: 'rgba(33, 33, 33, 0.7)'
												}}
											>
												{item.Event.toUpperCase()}
											</Typography>

											{item?.StartTime && item?.EndTime && (
												<Box
													sx={{
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														gap: '5px',
														fontWeight: '400',
														fontSize: '12px',
														color: '#99A0A8'
													}}
												>
													<AccessTime sx={{ color: 'inherit', fontSize: '18px' }} />

													<Typography sx={{ color: 'inherit', fontSize: 'inherit' }}>{getTimeDifferenceInString(item?.EndTime, item?.StartTime)}</Typography>
												</Box>
											)}
										</Stack>
										<Typography
											sx={{
												fontWeight: '400',
												fontSize: '12px',
												color: '#99A0A8'
											}}
										>
											{formatAMPM(new Date(item?.StartTime))}
										</Typography>
									</Box>
								</Box>
							</Box>
						))}
					</Stack>
				</div>
			</Box>
		);
	};

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		'&:nth-of-type(even)': {
			backgroundColor: '#edf0f280'
		},
		// hide last border
		'&:last-child td, &:last-child th': {
			border: 0
		}
	}));

	const handleOnPopoverClose = () => {
		setElRefPoint(null);
		setSelectedAgent(null);
		setIsPopoverOpen(false);
	};

	const handleOnEventsPopoverClose = () => {
		setElRefPoint(null);
		setSelectedAgent(null);
		setIsEventsPopoverOpen(false);
	};

	const handleOnColumnsPopoverClose = () => {
		setColumnsElRefPoint(null);
		setIsColumnsPopoverOpen(false);
	};

	const handleOnSelectColumnChange = (selected: any) => {

		let selectedIds: string[] = []
		let orderedSelected = [];

		selected.map((selected: any) => {
			selectedIds.push(selected.id)
		})

		if (selectedIds.includes("AgentId")) {
			orderedSelected.push({ id: 'AgentId', label: 'Agent Id', minWidth: 120 })
		}
		if (selectedIds.includes("AgentName")) {
			orderedSelected.push({ id: 'AgentName', label: 'Agent Name', minWidth: 80 })
		}
		if (selectedIds.includes("agentAction")) {
			orderedSelected.push({ id: 'agentAction', label: '', minWidth: 30 })
		}
		if (selectedIds.includes("CampaignNameOfLastCall")) {
			orderedSelected.push({ id: 'CampaignNameOfLastCall', label: 'Campaign Name', draggable: true })
		}
		if (selectedIds.includes("CallFrom_SkillName")) {
			orderedSelected.push({ id: 'CallFrom_SkillName', label: 'Call From Skill', draggable: true })
		}
		if (selectedIds.includes("CallType")) {
			orderedSelected.push({ id: 'CallType', label: 'Call Type', draggable: true, minWidth: 50 })
		}
		if (selectedIds.includes("AgentPhoneNumber")) {
			orderedSelected.push({ id: 'AgentPhoneNumber', label: 'Agent Phone No.', minWidth: 50 })
		}
		if (selectedIds.includes("sip_location")) {
			orderedSelected.push({ id: 'sip_location', label: 'SIP Location', minWidth: 50 })
		}
		if (selectedIds.includes("SkillsFormatted")) {
			orderedSelected.push({ id: 'SkillsFormatted', label: 'Skills', minWidth: 50 })
		}
		if (selectedIds.includes("GroupsFormatted")) {
			orderedSelected.push({ id: 'GroupsFormatted', label: 'Groups', minWidth: 50 })
		}
		if (selectedIds.includes("CustomerPhoneNumber")) {
			orderedSelected.push({ id: 'CustomerPhoneNumber', label: 'Customer No.', minWidth: 50 })
		}
		if (selectedIds.includes("AgentMode")) {
			orderedSelected.push({ id: 'AgentMode', label: 'Mode', draggable: true, minWidth: 30 })
		}
		if (selectedIds.includes("HoldDuration")) {
			orderedSelected.push({ id: 'HoldDuration', label: 'Hold Time', minWidth: 65, type: 'TIME' })
		}
		if (selectedIds.includes("AgentState")) {
			orderedSelected.push({ id: 'AgentState', label: 'State', draggable: true, minWidth: 50 })
		}
		if (selectedIds.includes("CallStatus")) {
			orderedSelected.push({ id: 'CallStatus', label: 'Last Call Status', minWidth: 50 })
		}
		if (selectedIds.includes("Since")) {
			orderedSelected.push({ id: 'Since', label: 'Duration', minWidth: 50, type: 'TIME' })
		}
		if (selectedIds.includes("adminAction")) {
			orderedSelected.push({ id: 'adminAction', label: 'Admin Action', minWidth: 185 })
	}
		if (selectedIds.includes("hamburger")) {
			orderedSelected.push({ id: 'hamburger', label: '', minWidth: 20 })
		}
		props?.handleFilterChange?.(agentListFilter, filterSection, props?.widgetId, orderedSelected);
		setSelectedColumns(orderedSelected);
		handleOnColumnsPopoverClose();
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const getTableData = async (filters = '0') => {
		let data = await getAgentList({ filters, type: filterType });
		const updatedRowData = mapRowDataWithThreshold(thresholdItems, data);
		for (const row of updatedRowData) {
			const skills = row.AgentSkills?.split(',') || [];
			const groups = row.AgentGroups?.split(',') || [];
			const tempSkills = [...skills];
			const tempGroups = [...groups];
			if(groups.length > 3) {
				row.GroupsFormatted = (
					<div>
						{groups.slice(0, 3).join(',')}
						<span key="badge" className="badge" title={tempGroups.slice(3).join(',')}>
							{`+${groups.length - 3}`}
						</span>
					</div>
				);
			}
			else {
				row.GroupsFormatted = groups.join(',');
			}
			if (skills.length > 3) {
				row.SkillsFormatted = (
					<div>
						{skills.slice(0, 3).join(',')}
						<span key="badge" className="badge" title={tempSkills.slice(3).join(',')}>
							{`+${skills.length - 3}`}
						</span>
					</div>
				);
			} else {
				row.SkillsFormatted = skills.join(',');
			}
			if (row && row.PauseReason) {
				row.AgentState = row.PauseReason
			}
		}
		setRowData(updatedRowData);
		if (searchText.length) {
			const filteredRows = updatedRowData.filter((item: any) => item.AgentName.toLowerCase().includes(searchText));
			setFilteredRowData(filteredRows);
		} else {
			setFilteredRowData(updatedRowData);
		}
	};

	const handleOnSearch = (event: any) => {
		setSearchText(event.target.value.length ? event.target.value.toLowerCase() : '');
		if (event?.target?.value?.length) {
			const searchText = event.target.value.toLowerCase();
			const filteredRows = rowData.filter((item: any) => item.AgentName.toLowerCase().includes(searchText));
			setFilteredRowData(filteredRows);
		} else {
			setFilteredRowData(rowData);
		}
	};

	const getSettings = () => {
		try {
			getAdminSettingsList().then((resp: any) => {
				setSettings(resp.Data);
				resp?.Data?.map((item: any) => {
					if (item?.ParameterCode === "BARGEIN_CALL_TYPE") {
						if (item?.ParameterValue === null) {
							setBargeinCallType(item?.DefaultValue);
						} else if (item?.ParameterValue !== null) {
							setBargeinCallType(item?.ParameterValue);
						}
					}
					if (item?.ParameterCode === "SIP_CLIENT") {
						if (item?.ParameterValue === null) {
							setSipClient(item?.DefaultValue);
						} else if (item?.ParameterValue !== null) {
							setSipClient(item?.ParameterValue);
						}
					}
					if (item?.ParameterCode === "SIP_URL") {
						if (item?.ParameterValue === null) {
							setSipUrl(item?.DefaultValue);
						} else if (item?.ParameterValue !== null) {
							setSipUrl(item?.ParameterValue);
						}
					}
				})
			})
				.catch((e) => {
					console.log("error", e);
				});
		} catch (e) {
			console.log("error2 2 2 22", e);
		}
	};

	const continuousBargeEnabled = () => {
		getContinuousBargeStatus().then((resp: any) => {
			if(resp?.isContinueBargeEnabled){
				setContinuousBarge(resp?.isContinueBargeEnabled);
			}
		})
	};

	const agentClick = async (action: string, agent: any) => {
		if (action === 'LOGOUT') {
			setOpenLogOffAlert(true);
			setLogOffDetails(agent);
		} else if (action === 'RELEASE') {
			setOpenAlert(true);
			setReleaseDetails(agent);
		} else if (action === 'BARGEIN') {
			setSelectedAgent(agent);
			setOnSelect(true);
			toggleBargeInDrawer();
		} else if (action === "SCREEN_BARGEIN") {
			setScreenBargeinData(agent);
		}
	};

	const agentDetails = async (event: any, agent: any) => {
		setSelectedAgent(agent);
		const agentData = await getAgentStateDetail({ agentId: agent.AgentUniqueId });
		setSelectedAgentDetails(agentData.find((eachAgent) => eachAgent));
		setElRefPoint({ top: event.clientY, left: event.clientX });
		setIsPopoverOpen(true);
	};

	const onHamburgerClick = (event: any) => {
		setColumnsElRefPoint({ top: event.clientY, left: event.clientX });
		setIsColumnsPopoverOpen(true);
	};

	const agentEvents = async (event: any, agent: any) => {
		setSelectedAgent(agent);
		let response = await getAgentEvents({ agentId: agent.AgentUniqueId });
		response = response.map((dataItem: any) => ({
			...dataItem,
			StartTime: dataItem.StartTime ? `${dataItem.StartTime.toString().split(' ')[0]}T${dataItem.StartTime.toString().split(' ')[1]}` : '',
			EndTime: dataItem.EndTime ? `${dataItem.EndTime.split(' ')[0]}T${dataItem.EndTime.toString().split(' ')[1]}` : ''
		}));
		setSelectedAgentEventDetails(response);
		setElRefPoint({ top: event.clientY, left: event.clientX });
		setIsEventsPopoverOpen(true);
	};

	const toggleBargeInDrawer = () => {
		setIsBargeInOpen(!isBargeInOpen);
	};

	const toggleBargeInDrawerSip = (value: any) => {
		setIsBargeInOpen(!isBargeInOpen);
		setBargeinCallType('');
		setOnSelect(false)
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = agentListFilter.filter((item: any) => item.id !== id);
		setAgentListFilter(newFilter);
		props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId, selectedColumns);
	};

	const clearFilter = () => {
		setAgentListFilter([]);
		props?.handleFilterChange?.([], filterSection, props?.widgetId, selectedColumns);
	};

	const handleFilterExistingData = (filteredData: any) => {
		setAgentListFilter(filteredData);
		props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId, selectedColumns);
	};

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType, filterSection: newfilterSection }: any) => {
		console.log("responseLength", responseLength)
		console.log("selectedFilters", selectedFilters)
		setAgentListFilter(selectedFilters);
		setFilterType(newFilterType);
		setFilterSection(newfilterSection);
		props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId, selectedColumns);
		toggleDrawer(false);
	};

	const handleDragStart = (event: React.DragEvent<HTMLDivElement>, item: any) => {
		event.dataTransfer?.setData('agentData', JSON.stringify(item));
	};

	const handleOnDrop = (event: React.DragEvent<HTMLDivElement>) => {
		const agentData: any = JSON.parse(event.dataTransfer?.getData('agentData') || '{}');

		if (!groupingColumns.find((col: any) => col.id === agentData.id)) {
			setGroupingColumns([...groupingColumns, agentData]);
		}
		return;
	};

	const toggleThresholdDrawer = (isOpen: boolean, data: any = null) => {
		setIsThresholdDrawerOpen(isOpen);
		if (data) {
			setThresholdItems(data);
		}
	};

	React.useEffect(() => {
		return () => {
			clearInterval(interval);
		};
	}, []);

	React.useEffect(() => {
		const filterIds = (agentListFilter.length && !(responseLength === agentListFilter.length)) ? agentListFilter.map((item: any) => item.id).join(',') : '0';
		console.log("filterIds", filterIds, responseLength, agentListFilter.length, (agentListFilter.length || !(responseLength === agentListFilter.length)))
		getTableData(filterIds);
		if (interval) {
			clearInterval(interval);
		}

		interval = setInterval(() => getTableData(filterIds), WIDGET_REFRESH_INTERVAL.AGENT_LIST);
	}, [agentListFilter, thresholdItems, searchText]);

	const filterItems = agentListFilter.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));

	// const autoLogoffFunction = (data: any) => {
	// 	// Define the mapping of agent states and their corresponding time limits
	// 	const agentState: any = {
	// 	  READY_TIME_LIMIT: "READY",
	// 	  AUX_TIME_LIMIT: "AUX",
	// 	  ACW_TIME_LIMIT: "ACW",
	// 	  PAUSE_TIME_LIMIT: "PAUSED",
	// 	};

	// 	// Extract the setting names from the agentState object
	// 	const agentSettings = Object.keys(agentState);

	// 	// Iterate through each row of data
	// 	data?.forEach((row: any) => {
	// 	  // Iterate through each settingName (assuming it's defined somewhere)
	// 	  settings?.forEach((settingName: any) => {
	// 		if (agentSettings.includes(settingName?.ParameterCode)) {
	// 		  const rowSince = parseInt(row?.Since);
	// 		  const settingParameterValue = parseInt(settingName?.ParameterValue ==null ? settingName?.DefaultValue : settingName?.ParameterValue);
	// 		//   console.log("settingName->",settingName,"---->",settingParameterValue);
	// 		  if (settingParameterValue > 0) {
	// 			// Check if the rowSince time is greater than the settingParameterValue
	// 			// and if the AgentState matches the expected state from agentState
	// 			if (
	// 			  rowSince > settingParameterValue &&
	// 			  row.AgentState === agentState[settingName?.ParameterCode]
	// 			) {
	// 			  handleLogOff(row); // Call the handleLogOff function
	// 			}
	// 		  }
	// 		}
	// 	  });
	// 	});
	//   };


	// React.useEffect(()=>{
	// 	getSettings();
	// },[])

	// const  autoLogoffFunction=(data:any)=> {
	// 	const agentState:any = {"READY_TIME_LIMIT": 'READY',"AUX_TIME_LIMIT": 'AUX',"ACW_TIME_LIMIT": 'ACW',"PAUSE_TIME_LIMIT": 'PAUSED'};
	// 	const agentSettings=Object.keys(agentState)
	// 	data?.forEach((row:any)=>{
	// 		settings?.forEach((settingName:any)=>{
	// 			if(agentSettings.includes(settingName?.ParameterCode))
	// 			{
	// 				const rowSince=parseInt(row?.Since);
	// 				const settingParameterValue=parseInt(settingName?.ParameterValue);
	// 				if(rowSince > settingParameterValue && row.AgentState===agentState[settingName?.ParameterCode])
	// 				{
	// 					 handleLogOff(row);
	// 				}
	// 			}
	// 			else
	// 			{
	// 				return 
	// 			}
	// 	})
	// 	})
	// }

	// React.useEffect(()=>{
	// 	getSettings();
	// 	},[])




	const getTableRows = (data: any) => {
		// autoLogoffFunction(data);
		return data.map((row: any, rowIdx: number) => {
			return (
				<StyledTableRow hover role="checkbox" tabIndex={-1} key={row.SNO}>
					{groupingColumns.map((groupName: any, idx: number) => {
						return <StyledTableCell key={groupName + idx} align="left"></StyledTableCell>;
					})}
					{selectedColumns.map((column: any) => {
						const value = row[column.id];
						if (column.id === 'agentAction') {
							return (
								<StyledTableCell key={column.id + rowIdx} align={column.align} className="">
									<span className="icon-row">
										<span className="icon-col-sm">
											<IconButton disableRipple onClick={(event) => agentDetails(event, row)}>
												<img src={`${process.env.PUBLIC_URL}/icons/table-info-icon.svg`} width="20" alt="Info"></img>
											</IconButton>
										</span>
										<span className="icon-col-sm">
											<IconButton disableRipple onClick={(event) => agentEvents(event, row)}>
												<img src={`${process.env.PUBLIC_URL}/icons/table-graph-icon.svg`} width="20" alt="Graph"></img>
											</IconButton>
										</span>
									</span>
								</StyledTableCell>
							);
						} else if (column.id === 'adminAction') {
							return (
								<StyledTableCell key={column.id + rowIdx} align={'center'}>
									<span className="icon-row">
										<span className="icon-col">
											<IconButton disableRipple onClick={() => agentClick('RELEASE', row)} aria-label="delete">
												<img src={`${process.env.PUBLIC_URL}/icons/table-release-icon.svg`} width="16" alt="Release"></img>
											</IconButton>
										</span>
										<span className="icon-col">
											<IconButton disableRipple onClick={() => agentClick('LOGOUT', row)}>
												<img src={`${process.env.PUBLIC_URL}/icons/table-logout-icon.svg`} width="16" alt="Logout"></img>
											</IconButton>
										</span>
										<span className="icon-col">
											<IconButton disableRipple disabled={row.AgentState !== 'BUSY'} onClick={() => agentClick('BARGEIN', row)}>
												<img src={`${process.env.PUBLIC_URL}/icons/table-bargein-icon.svg`} width="16" alt="Bargein"></img>
											</IconButton>
										</span>
									</span>
								</StyledTableCell>
							);
						} else if (column.id === 'hamburger') {
							return (
								<StyledTableCell key={column.id + rowIdx} align={'center'}>
									{value === 'expand' && (
										<span className="icon-row">
											<span className="icon-col">
												<img src={`${process.env.PUBLIC_URL}/icons/table-expand-icon.svg`} width="10" alt="Expand"></img>
											</span>
										</span>
									)}
								</StyledTableCell>
							);
						} else if (column.id === 'AgentState') {
							return (
								<StyledTableCell key={column.id} align={column.align}>
									{row?.PauseReason ?
										<>
											<span className={STATUS_CLASS_MAP['PAUSED']}>{value}</span>
										</>
										:
										<>
											<span className={STATUS_CLASS_MAP[value]}>{value}</span>
										</>
									}
								</StyledTableCell>
							);
						} else {
							return (
								<StyledTableCell
									key={column.id + rowIdx}
									align={column.align}
									sx={{
										whiteSpace: 'nowrap',
										textOverflow: 'ellipsis',
										overflow: 'hidden'
									}}
									className={`${row[column.id]?.warning || ''}`}
								>
									{(column.type === 'MIN_SEC' && secondsToMinSec(row[column.id]?.value || row[column.id])) ||
										(column.type === 'TIME' && secondsToTime(row[column.id]?.value || row[column.id])) ||
										value}
								</StyledTableCell>
							);
						}
					})}
				</StyledTableRow>
			);
		});
	};

	function getColor(numOfSteps: number = 1, step: number = 0) {
		let r = 0,
			g = 0,
			b = 0;
		const h = step / numOfSteps;
		const i = ~~(h * 6);
		const f = h * 6 - i;
		const q = 1 - f;
		switch (i % 6) {
			case 0:
				r = 1;
				g = f;
				b = 0;
				break;
			case 1:
				r = q;
				g = 1;
				b = 0;
				break;
			case 2:
				r = 0;
				g = 1;
				b = f;
				break;
			case 3:
				r = 0;
				g = q;
				b = 1;
				break;
			case 4:
				r = f;
				g = 0;
				b = 1;
				break;
			case 5:
				r = 1;
				g = 0;
				b = q;
				break;
		}
		const c = '#' + ('00' + (~~(r * 255)).toString(16)).slice(-2) + ('00' + (~~(g * 255)).toString(16)).slice(-2) + ('00' + (~~(b * 255)).toString(16)).slice(-2);
		return c;
	}

	const handleClose = () => {
		setOpenAlert(false);
	};
	const handleLogOffClose = () => {
		setOpenLogOffAlert(false);
	};

	function getRowData(row: any) {
		row = row?.map((item: any) => {
			let rowObj = {
				...item,
				SkillsFormatted: item?.AgentSkills ? item?.AgentSkills : item?.AgentSkills,
				GroupsFormatted: item?.AgentGroups ? item?.AgentGroups : item?.AgentGroups,
				Since: secondsToTime(item?.Since),
				HoldDuration: secondsToTime(item?.HoldDuration),
			};
			return rowObj;
		});
		return row;
	}

	const handleLogOff = async (row: any) => {
		console.log("row here", row);
		// await logoutAgent({ agentUniqueID: logOffDetails.AgentUniqueId, agentId: logOffDetails.AgentId });
		await logoutAgent({ agentUniqueID: row.AgentUniqueId, agentId: row.AgentId });
		setOpenLogOffAlert(false);
	};
	const handleRelease = async () => {
		await releaseAgent({ agentUniqueID: releaseDetails.AgentUniqueId, agentId: releaseDetails.AgentId });
		setOpenAlert(false);
	};

	React.useEffect(() => {
		if (groupingColumns.length && filteredRowData.length) {
			let groupedData = {};
			for (const data of filteredRowData) {
				groupingColumns
					.reduce((groupAcc: any, column: any, groupIdx: number) => {
						const key = data[column.id] || '';
						groupAcc[key] = groupAcc[key] || (groupIdx === groupingColumns.length - 1 ? [] : {});
						return groupAcc[key];
					}, groupedData)
					.push(data);
			}
			setGroupedData(groupedData);
		}
	}, [groupingColumns, filteredRowData]);


	let finalTableData: any = []
	const getAgentListTableData = () => {
		if (groupingColumns.length) {
			finalTableData = []
			getFinalRows(groupedData, 0);
			return finalTableData;
		} else {
			return filteredRowData
		}
	}

	const getFinalRows: any = (data: any, index: number) => {
		if (!Array.isArray(data)) {
			return Object.entries(data).map(([groupName, groupRowData]) => {
				const extraCell: any = {}
				for (let i = 0; i < index; i++) {

					extraCell['extraCell' + i] = ""

				}
				extraCell['index'] = index
				extraCell['groupName'] = groupName;
				finalTableData.push(extraCell);
				getFinalRows(groupRowData, index + 1)
			})

		} else {
			finalTableData = finalTableData.concat(data);
		}
	}

	function AgentListHeaderContent() {
		return (
			<TableRow>
				{groupingColumns.map((groupName: any, idx: number) => {
					return <StyledTableCell key={groupName + idx} align="left"></StyledTableCell>;
				})}
				{selectedColumns.map((column: any, idx: number) => {
					if (column.id === 'adminAction') {
						return (
							<StyledTableCell key={column.id + idx} align="center" style={{ minWidth: column.minWidth }}>
								{column.label}
								<span className="icon-row">
									<span className="icon-col">Release</span>
									<span className="icon-col">Logout</span>
									<span className="icon-col">Bargein</span>
									{checkRoles("ROLE_SCREEN_RECORDING") && <span className="icon-col icon-full-width">Screen Bargein</span>}
								</span>
							</StyledTableCell>
						);
					} else if (column.draggable) {
						return (
							<StyledTableCell
								key={column.id + idx}
								className="draggable-table-cell"
								align={'left'}
								draggable={true}
								sx={{
									whiteSpace: 'nowrap'
								}}
								onDragStart={(event) =>
									handleDragStart(event, {
										label: column.label,
										id: column.id
									})
								}
							>
								<Typography display={'flex'} alignItems={'center'}>
									<span style={{ padding: '0 0.2rem' }}>
										<DragIcon />
									</span>
									{column.label}
								</Typography>
							</StyledTableCell>
						);
					} else if (column.id === 'hamburger') {
						return (
							<StyledTableCell
								key={column.id + idx}
								style={{
									minWidth: column.minWidth,
									textAlign: 'center'
								}}
							>
								<IconButton onClick={onHamburgerClick}>
									<img src={`${process.env.PUBLIC_URL}/icons/table-hamburger-icon.svg`} width="20" alt="Hamburger" style={{ marginTop: '5px' }}></img>
								</IconButton>
							</StyledTableCell>
						);
					} else {
						return (
							<StyledTableCell key={column.id + idx} align={column.align} style={{ minWidth: column.minWidth }}>
								{column.label}
							</StyledTableCell>
						);
					}
				})}
			</TableRow>
		)
	}

	function rowContent(_index: number, row: any) {
		let rowIdx = _index
		if (row.hasOwnProperty('groupName')) {
		}
		return (
			(row.hasOwnProperty('groupName')) ?
				<React.Fragment>
					{Object.keys(row).map((key, i) => {
						let colorr = getColor(groupingColumns.length, _index) + '4d';
						if (key === 'groupName') {
							return <StyledTableCell key={key} colSpan={selectedColumns.length + groupingColumns.length} sx={{ padding: '5px 16px', background: colorr }}>
								{row.groupName}
							</StyledTableCell>
						} if (key.includes('extraCell')) {
							return <StyledTableCell key={key} sx={{ background: colorr }}>
								{''}
							</StyledTableCell>
						}
					})}
				</React.Fragment >
				:

				<React.Fragment>

					{groupingColumns.map((groupName: any, idx: number) => {
						return <StyledTableCell key={groupName + idx} align="left"></StyledTableCell>;
					})}
					{selectedColumns.map((column: any) => {
						const value = row[column.id];
						if (column.id === 'agentAction') {
							return (
								<StyledTableCell key={column.id + rowIdx} align={column.align} className="">
									<span className="icon-row">
										<span className="icon-col-sm">
											<IconButton disableRipple onClick={(event) => agentDetails(event, row)}>
												<img src={`${process.env.PUBLIC_URL}/icons/table-info-icon.svg`} width="20" alt="Info"></img>
											</IconButton>
										</span>
										<span className="icon-col-sm">
											<IconButton disableRipple onClick={(event) => agentEvents(event, row)}>
												<img src={`${process.env.PUBLIC_URL}/icons/table-graph-icon.svg`} width="20" alt="Graph"></img>
											</IconButton>
										</span>
									</span>
								</StyledTableCell>
							);
						} else if (column.id === 'adminAction') {
							return (
								<StyledTableCell key={column.id + rowIdx} align={'center'}>
									<span className="icon-row">
										<span className="icon-col">
											<IconButton disableRipple onClick={() => agentClick('RELEASE', row)} aria-label="delete">
												<img src={`${process.env.PUBLIC_URL}/icons/table-release-icon.svg`} width="16" alt="Release"></img>
											</IconButton>
										</span>
										<span className="icon-col">
											<IconButton disableRipple onClick={() => agentClick('LOGOUT', row)}>
												<img src={`${process.env.PUBLIC_URL}/icons/table-logout-icon.svg`} width="16" alt="Logout"></img>
											</IconButton>
										</span>
										<span className="icon-col">
											<IconButton disableRipple disabled={row.AgentState !== 'BUSY' || (row.AgentMode === 'DIGITAL' && row.AgentState === 'BUSY')} onClick={() => agentClick('BARGEIN', row)}>
												<img src={`${process.env.PUBLIC_URL}/icons/table-bargein-icon.svg`} width="16" alt="Bargein"></img>
											</IconButton>
										</span>
										{checkRoles("ROLE_SCREEN_RECORDING") && <span className="icon-col cursor-pointer" onClick={() => agentClick("SCREEN_BARGEIN", row)}>
											<ScreenBargeinIcon />
										</span>}
									</span>
								</StyledTableCell>
							);
						} else if (column.id === 'hamburger') {
							return (
								<StyledTableCell key={column.id + rowIdx} align={'center'}>
									{value === 'expand' && (
										<span className="icon-row">
											<span className="icon-col">
												<img src={`${process.env.PUBLIC_URL}/icons/table-expand-icon.svg`} width="10" alt="Expand"></img>
											</span>
										</span>
									)}
								</StyledTableCell>
							);
						} else if (column.id === 'AgentState') {
							return (
								<StyledTableCell key={column.id} align={column.align}>
									{row?.PauseReason ?
										<>
											<span className={STATUS_CLASS_MAP['PAUSED']}>{value}</span>
										</>
										:
										<>
											<span className={STATUS_CLASS_MAP[value]}>{value}</span>
										</>
									}
								</StyledTableCell>
							);
						}
						else {
							return (
								<StyledTableCell
									key={column.id + rowIdx}
									align={column.align}
									sx={{
										whiteSpace: 'nowrap',
										textOverflow: 'ellipsis',
										overflow: 'hidden'
									}}
									className={`${row[column.id]?.warning || ''}`}
								>
									{(column.type === 'MIN_SEC' && secondsToMinSec(row[column.id]?.value || row[column.id])) ||
										(column.type === 'TIME' && secondsToTime(row[column.id]?.value || row[column.id])) ||
										value}
								</StyledTableCell>
							);
						}
					})}
				</React.Fragment>
		);
	}

	const getTableStyle = () => {
		let height = 54;
		if (agentListFilter.length > 0) {
			height += 14;
		}
		return `calc(100% - ${height}px)`;
	}

	return (
		<Card className={!moreData ? "changedCustomCard" : resized ? "changedCustomCard" : "customCard"} ref={tableContainerRef}>
			<div className="card-header">
				<div className="card-title">
					Agent List <div className="card-title-badge">Live</div>
				</div>
				<div className="card-actions">
					<WidgetSearch onTextChange={handleOnSearch}></WidgetSearch>
					<WidgetDownload element={tableDataRef} name={'Agent List'} xlsx rowData={getRowData(rowData)} columns={selectedDownloadData}></WidgetDownload>
					<div className="card-icon" onClick={() => toggleDrawer(true)}>
						<Badge badgeContent={agentListFilter.length} color="primary">
							<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
						</Badge>
					</div>
					<div onClick={() => toggleThresholdDrawer(true)}>
						<WidgetGraph></WidgetGraph>
					</div>
				</div>
			</div>
			<FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} existingSelectedFilters={agentListFilter} setResponseLength={setresponseLength} allowedFilters={allowedFilters} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData}/>
			{/* <FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} existingSelectedFilters={agentListFilter} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData}/> */}
			<Box sx={{ pt: 2, pl: 2 }}>
				{agentListFilter.length > 0 && (
					<div className="filter-outer">
						<div className="filter-container">
							<Stack direction="row" spacing={1}>
								{filterItems}
							</Stack>
						</div>
						<div className="filter-remove" onClick={clearFilter}>
							Remove
						</div>
					</div>
				)}
			</Box>
			<div className="search-drag-drop" onDrop={handleOnDrop} onDragOver={(e) => e.preventDefault()}>
				{groupingColumns.length > 0 && (
					<Box className={classes.dragdropItemsWrapper}>
						<div className="filter-outer">
							<div className="filter-container">
								<Stack direction="row" spacing={1}>
									{groupingColumns.map((item: any, index: number) => (
										<Chip
											key={index}
											label={item.label}
											color="primary"
											variant="outlined"
											onDelete={() => {
												setGroupingColumns((prev: any) => prev.filter((_: any) => _.id !== item.id));
											}}
											deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />}
										/>
									))}
								</Stack>
							</div>
							<div className="filter-remove" onClick={() => setGroupingColumns([])}>
								Remove
							</div>
						</div>
					</Box>
				)}
				<Typography
					component={'span'}
					color="#99A0A8"
					position="absolute"
					// top={'50'}
					// left={'50'}
					fontSize={'12px'}
				>
					{groupingColumns.length < 3 && 'Drag and drop the metrics for grouping '}
					{/* <span>
					(Currently you don’t have any metrics which can be grouped)
				</span> */}
				</Typography>
			</div>
			<Paper sx={{
				width: '100%', overflow: 'hidden',
				height: getTableStyle(),
			}}>
				{/* For auto height adjust uncomment below line
					<TableContainer className="custom-table-scroll" sx={{ maxHeight: '500vw' }}> */}
				<VirtualTable
					tableDataRef={tableDataRef}
					data={getAgentListTableData()}
					HeaderContent={AgentListHeaderContent}
					rowContent={rowContent}
				/>
			</Paper>
			<Popover
				open={isPopoverOpen}
				onClose={handleOnPopoverClose}
				anchorReference="anchorPosition"
				anchorPosition={eleRefPoint}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'left'
				}}
			>
				{getPopoverContent()}
			</Popover>
			<Popover
				open={isEventsPopoverOpen}
				onClose={handleOnEventsPopoverClose}
				anchorReference="anchorPosition"
				anchorPosition={eleRefPoint}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'left'
				}}
			>
				{getEventsPopoverContent()}
			</Popover>
			{/* {bargeinCallType === "Normal" &&
				<BargeInDrawer isOpen={isBargeInOpen} toggleDrawer={toggleBargeInDrawer} agentDetails={selectedAgent} />
			}
			{bargeinCallType === 'SIP' && onselect &&
				<BargeInDrawerSip isOpen={isBargeInOpen} toggleDrawer={toggleBargeInDrawerSip} agentDetails={selectedAgent} callType={bargeinCallType} url={sipUrl} SipClient={sipClient} />
			} */}
			{/* modified to use single component for both normal and sip */}
			{onselect &&
				<BargeInDrawerSip isOpen={isBargeInOpen} toggleDrawer={toggleBargeInDrawerSip} agentDetails={selectedAgent} callType={bargeinCallType} url={sipUrl} SipClient={bargeinCallType === 'SIP' ? sipClient : undefined} />
			}
			{screenBargeinData && <VideoPlayerIcon
				row={{ liveRecordingData: screenBargeinData }}
				isLive={true}
				hideIcon={true}
				defaultOpen={true}
				onClose={() => { setScreenBargeinData(null) }}
			/>}

			<ThresholdDrawer isOpen={isThresholdDrawerOpen} title={'Set'} toggleDrawer={toggleThresholdDrawer} thresholdItems={thresholdItems} />
			<SelectPopover
				isOpen={isColumnsPopoverOpen}
				elementsArray={columns}
				anchorRef={columnsEleRefPoint}
				handlePopoverClose={handleOnColumnsPopoverClose}
				selectedElements={selectedColumns}
				handleOnSelectionChange={handleOnSelectColumnChange}
			/>
			<Dialog open={openAlert} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">{'Release'}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{`Are you sure you want to release agent ${releaseDetails?.AgentName}`}?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<div className="buttons-container">
						<Button variant="outlined" onClick={() => handleClose()}>
							Cancel
						</Button>
						<Button variant="contained" onClick={handleRelease} sx={{ ml: 2 }}>
							Release
						</Button>
					</div>
				</DialogActions>
			</Dialog>
			<Dialog open={openLogOffAlert} onClose={handleLogOffClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">{'Logout'}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{`Are you sure you want to Logout agent ${logOffDetails?.AgentName}`}?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<div className="buttons-container">
						<Button variant="outlined" onClick={() => handleLogOffClose()}>
							Cancel
						</Button>
						<Button variant="contained" onClick={() => { handleLogOff(logOffDetails) }} sx={{ ml: 2 }}>
							Logout
						</Button>
					</div>
				</DialogActions>
			</Dialog>
		</Card>
	);
}
